@charset "UTF-8";
/* ---------------------------------------------------------------------- */
/*	Import fonts
 /* ---------------------------------------------------------------------- */
@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,300,600,700);
@import url(https://fonts.googleapis.com/css?family=Raleway:400,300,200,100,500,600,700,800,900);
/* ---------------------------------------------------------------------- */
/*	Basic Elements & Classes
 /* ---------------------------------------------------------------------- */
body {
  color: #000000;
  direction: ltr;
  font-family: "Open+Sans", sans-serif;
  font-size: 13px;
  padding: 0px !important;
  margin: 0px !important;
  background: #ffffff;
}

a, a:focus, a:hover, a:active {
  outline: 0 !important;
}

h1, h2, h3 {
  font-family: "Raleway", sans-serif;
}

/* ---------------------------------------------------------------------- */
/*	Forms
 /* ---------------------------------------------------------------------- */
textarea, input[type=text], input[type=password], input[type=datetime], input[type=datetime-local], input[type=date], input[type=month], input[type=time], input[type=week], input[type=number], input[type=email], input[type=url], input[type=search], input[type=tel], input[type=color] {
  background-color: #FFFFFF;
  border: 1px solid #D5D5D5;
  border-radius: 0 0 0 0 !important;
  color: #858585;
  font-family: inherit;
  font-size: 14px;
  line-height: 1.2;
  padding: 5px 4px;
  transition-duration: 0.1s;
  box-shadow: none;
}

textarea:hover, input[type=text]:hover, input[type=password]:hover, input[type=datetime]:hover, input[type=datetime-local]:hover, input[type=date]:hover, input[type=month]:hover, input[type=time]:hover, input[type=week]:hover, input[type=number]:hover, input[type=email]:hover, input[type=url]:hover, input[type=search]:hover, input[type=tel]:hover, input[type=color]:hover {
  border-color: #B5B5B5;
}

textarea:focus, input[type=text]:focus, input[type=password]:focus, input[type=datetime]:focus, input[type=datetime-local]:focus, input[type=date]:focus, input[type=month]:focus, input[type=time]:focus, input[type=week]:focus, input[type=number]:focus, input[type=email]:focus, input[type=url]:focus, input[type=search]:focus, input[type=tel]:focus, input[type=color]:focus {
  border-color: rgba(82, 168, 236, 0.8);
  box-shadow: 0 0 8px rgba(82, 168, 236, 0.6);
  outline: 0 none;
}

label {
  font-weight: normal;
}

span.input-icon, span.input-help {
  display: block;
  position: relative;
}

.input-icon > input {
  padding-left: 25px;
  padding-right: 6px;
}

.input-icon.input-icon-right > input {
  padding-left: 6px;
  padding-right: 25px;
}

span.input-help > input {
  padding-left: 30px;
  padding-right: 6px;
}

.input-icon > [class*=fa-], .input-icon > [class*=clip-] {
  bottom: 0;
  color: #909090;
  display: inline-block;
  font-size: 14px;
  left: 5px;
  line-height: 35px;
  padding: 0 3px;
  position: absolute;
  top: 0;
  z-index: 2;
}

.input-icon.input-icon-right > [class*=fa-], .input-icon.input-icon-right > [class*=clip-] {
  left: auto;
  right: 4px;
}

.input-icon > input:focus + [class*=fa-], .input-icon > input:focus + [class*=clip-] {
  color: #557799;
}

.help-button {
  background-color: #65BCDA;
  border-radius: 100% 100% 100% 100%;
  color: #FFFFFF;
  cursor: default;
  position: absolute;
  font-size: 14px;
  font-weight: bold;
  height: 20px;
  padding: 0;
  text-align: center;
  width: 20px;
  line-height: 20px;
  top: 7px;
  left: 7px;
}

.help-button:before {
  content: "\f128";
  display: inline;
  font-family: FontAwesome;
  font-weight: 300;
  height: auto;
  text-shadow: none;
  font-style: normal;
}

select.form-control {
  background-color: #FFFFFF;
  border: 1px solid #D5D5D5;
  border-radius: 0 0 0 0;
  color: #858585;
}

select.form-control option {
  padding: 3px 4px;
}

.form-control.search-select {
  padding: 0 !important;
  box-shadow: none;
  border: none;
}

.select2-container .select2-choice {
  background-clip: padding-box !important;
  background-color: #FFFFFF !important;
  background-image: none !important;
  background-repeat: no-repeat !important;
  border: 1px solid #CCCCCC !important;
  border-radius: 0 !important;
  box-shadow: none !important;
  color: #555555 !important;
  display: block !important;
  height: 34px !important;
  line-height: 29px !important;
  margin-top: 3px !important;
  overflow: hidden !important;
  padding: 0 12px 0 8px !important;
  position: relative !important;
  text-decoration: none !important;
  white-space: nowrap !important;
}

.select2-container .select2-choice .select2-arrow {
  background-image: none !important;
  background-color: #FFFFFF !important;
  width: 28px !important;
  text-align: center;
}

.select2-container .select2-choice .select2-arrow b {
  background: none !important;
  display: block;
  height: 100%;
  width: 100%;
}

.select2-container .select2-choice .select2-arrow b:before {
  content: "\f078";
  display: inline;
  font-family: FontAwesome;
  font-weight: 300;
  height: auto;
  text-shadow: none;
}

.select2-dropdown-open.select2-container-active .select2-choice .select2-arrow b:before {
  content: "\f077";
}

.select2-container-multi .select2-choices {
  background-image: none !important;
  background-color: #FFFFFF !important;
}

.limiterBox {
  border-top: 0;
  background-color: #65BCDA !important;
  padding: 3px 6px;
  font-size: 12px;
  color: #FFF;
  margin-top: 6px;
}

.limiterBox:after {
  display: none;
}

.limiterBox:before {
  display: block;
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  top: -8px;
  left: 50%;
  margin-left: -5px;
  border-color: transparent;
  border-style: solid;
  border-bottom-color: #65BCDA;
  border-width: 0 8px 8px;
}

textarea.autosize {
  vertical-align: top;
  transition: height 0.2s;
  -webkit-transition: height 0.2s;
  -moz-transition: height 0.2s;
}

.radio label.radio-inline, .checkbox label.checkbox-inline {
  display: inline-block;
}

.radio-inline, .radio-inline + .radio-inline, .checkbox-inline, .checkbox-inline + .checkbox-inline {
  margin-right: 10px !important;
  margin-top: 5px !important;
  margin-left: 0 !important;
  margin-bottom: 10px !important;
}

.radio label, .checkbox label {
  margin-bottom: 5px;
}

.checkbox-table {
  display: inline-block;
  margin: 2px 0 0 0;
  padding-left: 20px;
  line-height: 10px;
}

.checkbox-table label {
  margin-bottom: 0;
}

[class^=icheckbox_], [class*=icheckbox_], [class^=iradio_], [class*=iradio_] {
  float: left !important;
  margin: 0 5px 0 -20px !important;
}

.help-inline {
  margin-top: 6px;
  color: #737373;
}

.help-block.error {
  color: #B94A48;
}

.symbol.required:before {
  content: "*";
  display: inline;
  color: #E6674A;
}

.symbol.ok:before {
  content: "\f00c";
  display: inline;
  font-family: FontAwesome;
  color: #468847;
}

.has-error .note-editor, .has-error .cke_chrome {
  border-color: #B94A48 !important;
}

.form-group .text {
  display: block;
  font-size: 14px;
  font-weight: 400;
  margin-top: 7px;
}

/* ---------------------------------------------------------------------- */
/*	Generic Classes
 /* ---------------------------------------------------------------------- */
.no-padding {
  padding: 0 !important;
}

.no-display {
  display: none;
}

.example {
  margin: 15px 0;
  padding: 14px 19px;
}

.widget {
  -moz-box-sizing: content-box;
  color: #333;
  margin-bottom: 30px;
  padding: 10px 12px;
  position: relative;
}

.space5 {
  display: block;
  margin-bottom: 5px !important;
  clear: both;
}

.space7 {
  margin-bottom: 7px !important;
  clear: both;
}

.space10 {
  margin-bottom: 10px !important;
  clear: both;
}

.space12 {
  margin-bottom: 12px !important;
  clear: both;
}

.space15 {
  margin-bottom: 15px !important;
  clear: both;
}

.space20 {
  margin-bottom: 20px !important;
  clear: both;
}

.teal {
  color: #569099;
}

a.teal:hover {
  color: #4d8189;
}

.orange {
  color: #E6674A;
}

a.orange:hover {
  color: #E35434;
}

.bricky {
  color: #C83A2A;
}

a.bricky:hover {
  color: #B33426;
}

.circle-img {
  border-radius: 100% 100% 100% 100%;
}

.center {
  text-align: center;
}

.go-top {
  cursor: pointer;
  display: block;
  width: 30px;
  height: 30px;
  font-size: 16px;
  margin-bottom: 0;
  margin-right: 0;
  margin-top: -2px;
  text-decoration: none;
  line-height: 30px;
  text-align: center;
}

/* ---------------------------------------------------------------------- */
/*  Navbar and navbar elements
 /* ---------------------------------------------------------------------- */
.navbar {
  margin: 0 !important;
  padding: 0 !important;
  min-height: 42px;
  border-radius: 0;
  border-right: none;
  border-left: none;
  border-top: none;
  background: none;
  border-color: #ffffff;
}

.header-default .navbar {
  position: relative !important;
}

.navbar > .container .navbar-brand {
  padding: 8px 10px 0px;
  margin-left: 0;
  font-family: "Raleway", sans-serif;
  font-size: 25px;
}

.navbar > .container .navbar-brand i, .logo i {
  font-size: 20px;
}

.navbar-tools > ul {
  list-style: none;
}

.navbar-tools > ul > li > a {
  padding: 15px 15px 9px;
  font-size: 16px;
}

.navbar-tools li.view-all a {
  padding: 8px 8px 6px !important;
}

.navbar-tools > ul > li {
  float: left;
}

.navbar-tools .dropdown-menu {
  background: none repeat scroll 0 0 white;
  border: 1px solid #C7CBD5;
  border-radius: 0 0 4px 4px;
  box-shadow: none;
  list-style: none outside none;
  margin: 0;
  max-width: 300px;
  min-width: 166px;
  padding: 0;
  position: absolute;
  text-shadow: none;
  top: 100%;
  z-index: 1000;
}

.navbar-tools .drop-down-wrapper {
  height: 250px;
  width: 270px;
  overflow: hidden;
  position: relative;
}

.navbar-tools .drop-down-wrapper ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

ul.notifications li, ul.todo li, ul.posts li {
  min-width: 260px;
}

.navbar-tools > ul > li.dropdown {
  margin-left: 2px;
  margin-right: 2px;
}

.navbar-tools .dropdown-menu > li > a:hover, .navbar-tools .dropdown-menu > li > a:focus, .navbar-tools .dropdown-submenu:hover > a, .navbar-tools .dropdown-submenu:focus > a, .navbar-tools .drop-down-wrapper li a:hover, .navbar-tools .drop-down-wrapper li a:focus {
  background-color: #F4F6F9 !important;
  background-image: none;
  filter: none;
  color: #000;
  text-decoration: none;
}

.drop-down-wrapper ul > li:last-child a {
  border-bottom: none;
}

.navbar-tools .dropdown-menu li .dropdown-menu-title {
  display: block;
  font-weight: bold;
  margin: -1px;
  padding: 5px 10px;
}

.navbar-tools .dropdown-menu li p, .navbar-tools .dropdown-menu li a, .navbar-tools .drop-down-wrapper li p, .navbar-tools .drop-down-wrapper li a {
  color: #333333;
  font-size: 12px;
  font-weight: 300;
  margin: 0;
  padding: 8px 8px 6px;
  border-bottom: 1px solid rgba(100, 100, 100, 0.22);
  white-space: normal !important;
  display: block;
}

.navbar-tools .dropdown-menu > li:last-child a {
  border-bottom: none !important;
  border-radius: 0 0 6px 6px;
}

li.dropdown.current-user .dropdown-toggle {
  padding: 10px 4px 7px 9px;
}

li.dropdown.current-user .dropdown-menu li a {
  border-bottom: none !important;
}

.navbar-tools .dropdown-menu li p {
  font-weight: bold;
}

.navbar-tools .dropdown-menu li a .author {
  color: #0362FD;
  display: block;
}

.navbar-tools .dropdown-menu li a .preview {
  display: block;
}

.navbar-tools .dropdown-menu li a .time {
  font-size: 12px;
  font-style: italic;
  font-weight: 600;
  display: block;
  float: right;
}

.navbar-tools .dropdown-menu li.view-all a i {
  float: right;
  margin-top: 4px;
}

.navbar-tools .dropdown-menu.notifications li > a > .label {
  margin-right: 2px;
  padding: 2px 4px;
  text-align: center !important;
  width: 10px;
}

.navbar-tools .thread-image {
  margin-right: 8px;
  float: left;
  height: 50px;
  width: 50px;
}

.navbar-tools > ul > li.dropdown .dropdown-toggle .badge {
  border-radius: 12px 12px 12px 12px !important;
  font-size: 11px !important;
  font-weight: 300;
  padding: 3px 6px;
  position: absolute;
  right: 24px;
  text-align: center;
  text-shadow: none !important;
  top: 8px;
}

.navbar-toggle {
  border: none;
  border-radius: 0;
  margin-top: 5px;
  margin-bottom: 4px;
}

.navbar-toggle span {
  font-size: 16px;
}

/* ---------------------------------------------------------------------- */
/*	Search Box
 /* ---------------------------------------------------------------------- */
.search-box:before {
  content: " " !important;
}

.search-box {
  float: right;
}

.sidebar-search {
  margin: -3px 0 0 0;
  padding: 0 30px 0 5px;
  display: inline-block;
  background: #E4E5E6;
  border-radius: 5px;
  color: #555555;
  position: absolute;
  right: 10px;
  top: 15px;
  min-height: 28px;
  min-width: 28px;
}

.sidebar-search .form-group {
  margin: 0;
  padding: 0;
}

.sidebar-search .form-group input {
  background: none;
  border: none;
  width: 130px;
}

.sidebar-search .form-group input:focus {
  box-shadow: none;
}

.sidebar-search .form-group button {
  background: none;
  border: none;
  position: absolute;
  right: 4px;
  top: 5px;
  width: 28px;
}

.sidebar-search .form-group button i {
  color: #999999;
}

.search-box .show-search {
  position: absolute;
  right: 15px;
  top: 12px;
  background: #D9D9D9;
  padding: 4px 10px;
  border-radius: 5px;
}

.search-box .show-search:hover {
  text-decoration: none;
}

.search-box .show-search i {
  color: #999999;
}

/* ---------------------------------------------------------------------- */
/*	Buttons
 /* ---------------------------------------------------------------------- */
.btn {
  font-family: "Open Sans";
}

.btn-teal {
  background-color: #569099;
  border-color: #4d8189;
  color: #ffffff;
}

.btn-teal:hover, .btn-teal:focus, .btn-teal:active, .btn-teal.active {
  background-color: #4d8189;
  border-color: #447178;
  color: #ffffff;
}

.btn-teal.disabled:hover, .btn-teal.disabled:focus, .btn-teal.disabled:active, .btn-teal.disabled.active, .btn-teal[disabled]:hover, .btn-teal[disabled]:focus, .btn-teal[disabled]:active, .btn-teal[disabled].active, fieldset[disabled] .btn-teal:hover, fieldset[disabled] .btn-teal:focus, fieldset[disabled] .btn-teal:active, fieldset[disabled] .btn-teal.active {
  background-color: #569099;
  border-color: #569099;
  color: #ffffff;
}

.btn-beige {
  background-color: #cc9;
  border-color: #c4c488;
  color: #ffffff;
}

.btn-beige:hover, .btn-beige:focus, .btn-beige:active, .btn-beige.active {
  background-color: #c4c488;
  border-color: #bbbb77;
  color: #ffffff;
}

.btn-beige.disabled:hover, .btn-beige.disabled:focus, .btn-beige.disabled:active, .btn-beige.disabled.active, .btn-beige[disabled]:hover, .btn-beige[disabled]:focus, .btn-beige[disabled]:active, .btn-beige[disabled].active, fieldset[disabled] .btn-beige:hover, fieldset[disabled] .btn-beige:focus, fieldset[disabled] .btn-beige:active, fieldset[disabled] .btn-beige.active {
  background-color: #cc9;
  border-color: #cc9;
  color: #ffffff;
}

.btn-dark-beige {
  background-color: #afaa6d;
  border-color: #a6a05d;
  color: #ffffff;
}

.btn-dark-beige:hover, .btn-dark-beige:focus, .btn-dark-beige:active, .btn-dark-beige.active {
  background-color: #a6a05d;
  border-color: #979152;
  color: #ffffff;
}

.btn-dark-beige.disabled:hover, .btn-dark-beige.disabled:focus, .btn-dark-beige.disabled:active, .btn-dark-beige.disabled.active, .btn-dark-beige[disabled]:hover, .btn-dark-beige[disabled]:focus, .btn-dark-beige[disabled]:active, .btn-dark-beige[disabled].active, fieldset[disabled] .btn-dark-beige:hover, fieldset[disabled] .btn-dark-beige:focus, fieldset[disabled] .btn-dark-beige:active, fieldset[disabled] .btn-dark-beige.active {
  background-color: #afaa6d;
  border-color: #afaa6d;
  color: #ffffff;
}

.btn-orange {
  background-color: #e6674a;
  border-color: #e35434;
  color: #ffffff;
}

.btn-orange:hover, .btn-orange:focus, .btn-orange:active, .btn-orange.active {
  background-color: #e35434;
  border-color: #de421f;
  color: #ffffff;
}

.btn-orange.disabled:hover, .btn-orange.disabled:focus, .btn-orange.disabled:active, .btn-orange.disabled.active, .btn-orange[disabled]:hover, .btn-orange[disabled]:focus, .btn-orange[disabled]:active, .btn-orange[disabled].active, fieldset[disabled] .btn-orange:hover, fieldset[disabled] .btn-orange:focus, fieldset[disabled] .btn-orange:active, fieldset[disabled] .btn-orange.active {
  background-color: #e6674a;
  border-color: #e6674a;
  color: #ffffff;
}

.btn-purple {
  background-color: #57517b;
  border-color: #413d5c;
  color: #ffffff;
}

.btn-purple:hover, .btn-purple:focus, .btn-purple:active, .btn-purple.active {
  background-color: #4c476c;
  border-color: #413d5c;
  color: #ffffff;
}

.btn-purple.disabled:hover, .btn-purple.disabled:focus, .btn-purple.disabled:active, .btn-purple.disabled.active, .btn-purple[disabled]:hover, .btn-purple[disabled]:focus, .btn-purple[disabled]:active, .btn-purple[disabled].active, fieldset[disabled] .btn-purple:hover, fieldset[disabled] .btn-purple:focus, fieldset[disabled] .btn-purple:active, fieldset[disabled] .btn-purple.active {
  background-color: #57517b;
  border-color: #333;
  color: #ffffff;
}

.btn-dark-grey {
  background-color: #333;
  border-color: #333;
  color: #ffffff;
}

.btn-dark-grey:hover, .btn-dark-grey:focus, .btn-dark-grey:active, .btn-dark-grey.active {
  background-color: #262626;
  border-color: #1a1a1a;
  color: #ffffff;
}

.btn-dark-grey.disabled:hover, .btn-dark-grey.disabled:focus, .btn-dark-grey.disabled:active, .btn-dark-grey.disabled.active, .btn-dark-grey[disabled]:hover, .btn-dark-grey[disabled]:focus, .btn-dark-grey[disabled]:active, .btn-dark-grey[disabled].active, fieldset[disabled] .btn-dark-grey:hover, fieldset[disabled] .btn-dark-grey:focus, fieldset[disabled] .btn-dark-grey:active, fieldset[disabled] .btn-dark-grey.active {
  background-color: #333;
  border-color: #333;
  color: #ffffff;
}

.btn-med-grey {
  background-color: #666;
  border-color: #595959;
  color: #ffffff;
}

.btn-med-grey:hover, .btn-med-grey:focus, .btn-med-grey:active, .btn-med-grey.active {
  background-color: #595959;
  border-color: #4d4d4d;
  color: #ffffff;
}

.btn-med-grey.disabled:hover, .btn-med-grey.disabled:focus, .btn-med-grey.disabled:active, .btn-med-grey.disabled.active, .btn-med-grey[disabled]:hover, .btn-med-grey[disabled]:focus, .btn-med-grey[disabled]:active, .btn-med-grey[disabled].active, fieldset[disabled] .btn-med-grey:hover, fieldset[disabled] .btn-med-grey:focus, fieldset[disabled] .btn-med-grey:active, fieldset[disabled] .btn-med-grey.active {
  background-color: #666;
  border-color: #666;
  color: #ffffff;
}

.btn-light-grey {
  background-color: #999;
  border-color: #8c8c8c;
  color: #ffffff;
}

.btn-light-grey:hover, .btn-light-grey:focus, .btn-light-grey:active, .btn-light-grey.active {
  background-color: #8c8c8c;
  border-color: #808080;
  color: #ffffff;
}

.btn-light-grey.disabled:hover, .btn-light-grey.disabled:focus, .btn-light-grey.disabled:active, .btn-light-grey.disabled.active, .btn-light-grey[disabled]:hover, .btn-light-grey[disabled]:focus, .btn-light-grey[disabled]:active, .btn-light-grey[disabled].active, fieldset[disabled] .btn-light-grey:hover, fieldset[disabled] .btn-light-grey:focus, fieldset[disabled] .btn-light-grey:active, fieldset[disabled] .btn-light-grey.active {
  background-color: #999;
  border-color: #999;
  color: #ffffff;
}

.btn-bricky {
  background-color: #C83A2A;
  border-color: #b33426;
  color: #ffffff;
}

.btn-bricky:hover, .btn-bricky:focus, .btn-bricky:active, .btn-bricky.active {
  background-color: #b33426;
  border-color: #9e2e21;
  color: #ffffff;
}

.btn-bricky.disabled:hover, .btn-bricky.disabled:focus, .btn-bricky.disabled:active, .btn-bricky.disabled.active, .btn-bricky[disabled]:hover, .btn-bricky[disabled]:focus, .btn-bricky[disabled]:active, .btn-bricky[disabled].active, fieldset[disabled] .btn-bricky:hover, fieldset[disabled] .btn-bricky:focus, fieldset[disabled] .btn-bricky:active, fieldset[disabled] .btn-bricky.active {
  background-color: #C83A2A;
  border-color: #C83A2A;
  color: #ffffff;
}

.btn-green {
  background-color: #3D9400;
  border-color: #327b00;
  color: #ffffff;
}

.btn-green:hover, .btn-green:focus, .btn-green:active, .btn-green.active {
  background-color: #327b00;
  border-color: #286100;
  color: #ffffff;
}

.btn-green.disabled:hover, .btn-green.disabled:focus, .btn-green.disabled:active, .btn-green.disabled.active, .btn-green[disabled]:hover, .btn-green[disabled]:focus, .btn-green[disabled]:active, .btn-green[disabled].active, fieldset[disabled] .btn-green:hover, fieldset[disabled] .btn-green:focus, fieldset[disabled] .btn-green:active, fieldset[disabled] .btn-green.active {
  background-color: #3D9400;
  border-color: #3D9400;
  color: #ffffff;
}

.btn-blue {
  background-color: #364F6A;
  border-color: #2d4259;
  color: #ffffff;
}

.btn-blue:hover, .btn-blue:focus, .btn-blue:active, .btn-blue.active {
  background-color: #2d4259;
  border-color: #253648;
  color: #ffffff;
}

.btn-blue.disabled:hover, .btn-blue.disabled:focus, .btn-blue.disabled:active, .btn-blue.disabled.active, .btn-blue[disabled]:hover, .btn-blue[disabled]:focus, .btn-blue[disabled]:active, .btn-blue[disabled].active, fieldset[disabled] .btn-blue:hover, fieldset[disabled] .btn-blue:focus, fieldset[disabled] .btn-blue:active, fieldset[disabled] .btn-blue.active {
  background-color: #364F6A;
  border-color: #364F6A;
  color: #ffffff;
}

.btn-yellow {
  background-color: #FFB848;
  border-color: #ffae2f;
  color: #ffffff;
}

.btn-yellow:hover, .btn-yellow:focus, .btn-yellow:active, .btn-yellow.active {
  background-color: #ffae2f;
  border-color: #ffa415;
  color: #ffffff;
}

.btn-yellow.disabled:hover, .btn-yellow.disabled:focus, .btn-yellow.disabled:active, .btn-yellow.disabled.active, .btn-yellow[disabled]:hover, .btn-yellow[disabled]:focus, .btn-yellow[disabled]:active, .btn-yellow[disabled].active, fieldset[disabled] .btn-yellow:hover, fieldset[disabled] .btn-yellow:focus, fieldset[disabled] .btn-yellow:active, fieldset[disabled] .btn-yellow.active {
  background-color: #FFB848;
  border-color: #FFB848;
  color: #ffffff;
}

.btn-red {
  background-color: #E02222;
  border-color: #cc1d1d;
  color: #ffffff;
}

.btn-red:hover, .btn-red:focus, .btn-red:active, .btn-red.active {
  background-color: #cc1d1d;
  border-color: #b61919;
  color: #ffffff;
}

.btn-red.disabled:hover, .btn-red.disabled:focus, .btn-red.disabled:active, .btn-red.disabled.active, .btn-red[disabled]:hover, .btn-red[disabled]:focus, .btn-red[disabled]:active, .btn-red[disabled].active, fieldset[disabled] .btn-red:hover, fieldset[disabled] .btn-red:focus, fieldset[disabled] .btn-red:active, fieldset[disabled] .btn-red.active {
  background-color: #E02222;
  border-color: #E02222;
  color: #ffffff;
}

.btn-teal .caret, .btn-beige .caret, .btn-dark-beige .caret, .btn-orange .caret, .btn-purple .caret, .btn-dark-grey .caret, .btn-med-grey .caret, .btn-light-grey .caret, .btn-bricky .caret, .btn-green .caret, .btn-blue .caret, .btn-yellow .caret, .btn-red .caret {
  border-top-color: #ffffff;
}

.dropup .btn-teal .caret, .dropup .btn-beige .caret, .dropup .btn-dark-beige .caret, .dropup .btn-orange .caret, .dropup .btn-purple .caret, .dropup .btn-dark-grey .caret, .dropup .btn-med-grey .caret, .dropup .btn-light-grey .caret, .dropup .btn-bricky .caret, .dropup .btn-green .caret, .dropup .btn-blue .caret, .dropup .btn-yellow .caret, .dropup .btn-red .caret {
  border-bottom: 4px solid #FFFFFF;
}

.btn-squared {
  border-radius: 0 !important;
}

.btn {
  transition: all 0.3s ease 0s !important;
}

.btn-icon {
  background-color: #EFEFEF;
  background-image: -moz-linear-gradient(center top, #FAFAFA, #EFEFEF);
  border: 1px solid #DDDDDD;
  border-radius: 2px 2px 2px 2px;
  box-shadow: 0 1px 0 rgba(255, 255, 255, 0.8);
  cursor: pointer;
  height: 80px;
  display: block;
  font-size: 14px;
  margin-bottom: 15px;
  margin-top: 15px;
  padding: 5px 0 0;
  position: relative;
  text-align: center;
  transition: all 0.3s ease 0s;
  color: #333333;
}

.btn-icon:hover {
  border-color: #A5A5A5;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.25);
  color: #444444;
  text-decoration: none;
  text-shadow: 0 1px 0 #FFFFFF;
}

.btn-icon .badge {
  border-radius: 12px 12px 12px 12px !important;
  border-style: solid;
  border-width: 0;
  box-shadow: none;
  color: #FFFFFF !important;
  font-family: "Open Sans";
  font-size: 11px !important;
  font-weight: 300;
  padding: 3px 7px;
  position: absolute;
  right: -5px;
  text-shadow: none;
  top: -5px;
}

.btn-icon [class^=fa-], .btn-icon [class*=" fa-"], .btn-icon [class^=clip-], .btn-icon [class*=" clip-"] {
  clear: both;
  display: block;
}

.buttons-widget .btn, .buttons-widget .make-switch {
  margin-bottom: 5px;
}

.buttons-widget .btn-group-vertical .btn {
  margin-bottom: 0;
}

/* ---------------------------------------------------------------------- */
/*	Panel Tools
/* ---------------------------------------------------------------------- */
.panel-tools {
  position: absolute;
  right: 5px;
  top: 8px;
}

.panel-tools .btn-link {
  color: #0480BE;
}

.panel-tools .btn-link:hover {
  color: #036392;
}

.panel-tools .btn-link {
  color: #57A957;
}

.panel-tools .btn-link:hover {
  color: #417E41;
}

.panel-tools .btn-link {
  color: #C43C35;
}

.panel-tools .btn-link:hover {
  color: #972E28;
}

.panel-tools .btn-link {
  color: #333333;
}

.panel-tools .btn-link:hover {
  color: #000000;
}

.panel-tools .btn-link:focus, .panel-tools .btn-link:hover, .panel-tools .btn-link:active {
  text-decoration: none;
  outline: 0 !important;
}

.panel-tools .btn.expand:before {
  content: "\f077";
  display: inline;
  float: right;
  font-family: FontAwesome;
  font-weight: 300;
  height: auto;
  text-shadow: none;
}

.panel-tools .btn.collapses:before {
  content: "\f078";
  display: inline;
  float: right;
  font-family: FontAwesome;
  font-weight: 300;
  height: auto;
  text-shadow: none;
}

.panel-tools .btn {
  border-style: none;
}

.panel-tools .btn-link {
  color: #666666;
}

.panel-tools .btn-link:hover {
  color: #333333;
}

/* ---------------------------------------------------------------------- */
/*	Panels and panel elements
 /* ---------------------------------------------------------------------- */
.panel-heading {
  background-color: #f4f3f4 !important;
  border-bottom: 1px solid #CDCDCD;
  border-radius: 6px 6px 0 0;
  box-shadow: 0 1px 0 #FFFFFF inset;
  height: 36px;
  padding-left: 40px;
  position: relative;
}

.panel-heading > [class^=fa-], .panel-heading > [class*=" fa-"], .panel-heading > [class^=icon-], .panel-heading > [class*=" icon-"], .panel-heading > [class^=clip-], .panel-heading > [class*=" clip-"] {
  border-right: 1px solid #CDCDCD;
  box-shadow: 1px 0 0 0 #FFFFFF;
  opacity: 0.7;
  padding: 12px 10px 8px;
  position: absolute;
  left: 0;
  top: 0;
  height: 36px;
}

.panel-white {
  box-shadow: none;
}

.panel-white .panel-heading {
  background-color: #ffffff;
  background-image: none;
  border-bottom: 1px solid #CDCDCD;
  border-radius: 6px 6px 0 0;
  box-shadow: none;
  height: 36px;
  padding-left: 40px;
  position: relative;
}

.panel-white .panel-heading > [class^=fa-], .panel-white .panel-heading > [class*=" fa-"], .panel-white .panel-heading > [class^=icon-], .panel-white .panel-heading > [class*=" icon-"], .panel-white .panel-heading > [class^=clip-], .panel-white .panel-heading > [class*=" clip-"] {
  border-right: 1px solid #CDCDCD;
  box-shadow: none;
  opacity: 0.7;
  padding: 12px 10px 8px;
  position: absolute;
  left: 0;
  top: 0;
  height: 36px;
}

.panel-box {
  background: #FAFAFA;
  border: 1px solid #CDCDCD;
  border-radius: 6px 6px 6px 6px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  clear: both;
  margin-bottom: 16px;
  margin-top: 16px;
  position: relative;
}

.panel.panel-full-screen {
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9998;
}

.panel-scroll {
  position: relative;
  margin: 10px;
  padding: 0px;
  width: auto;
  height: 400px;
  overflow: hidden;
}

/* ---------------------------------------------------------------------- */
/*	Pagination
 /* ---------------------------------------------------------------------- */
.pagination li a {
  cursor: pointer;
}

.pagination.squared {
  border-radius: 0 !important;
}

.pagination.squared li:first-child a, .pagination.squared li:first-child > span {
  border-bottom-left-radius: 0px !important;
  border-left-width: 1px;
  border-top-left-radius: 0px !important;
}

.pagination.squared li:last-child > a, .pagination.squared li:last-child > span {
  border-bottom-right-radius: 0px !important;
  border-top-right-radius: 0px !important;
}

.pagination.pagination-purple li a, .pagination-purple .pagination li a {
  background: #F5F4F9;
  border: none !important;
  color: #333333;
  display: inline-block;
  margin-right: 1px;
}

.pagination.pagination-purple li a:hover, .pagination-purple .pagination li a a:hover {
  background: #ECEAF3;
  color: #222222;
  cursor: pointer;
}

.pagination.pagination-purple li:first-child a, .pagination-purple .pagination li:first-child a {
  border: none !important;
}

.pagination.pagination-purple li.disabled a, .pagination-purple .pagination li.disabled a {
  color: #AAAAAA !important;
}

.pagination.pagination-purple li.disabled a:hover, .pagination-purple .pagination li.disabled a:hover, .pagination.pagination-purple li.disabled a:focus, .pagination-purple .pagination li.disabled a:focus {
  background: #F5F4F9;
  cursor: default !important;
}

.pagination.pagination-purple li.active a, .pagination-purple .pagination li.active a {
  background: #57517B !important;
  border: none !important;
  color: #FFFFFF !important;
  cursor: default !important;
  opacity: 1;
}

.pagination-purple [class^=fa-], .pagination-purple [class*=" fa-"] {
  color: #57517B;
}

.pagination-purple li.disabled [class^=fa-], .pagination-purple li.disabled [class*=" fa-"] {
  opacity: 0.5;
}

.pagination.pagination-teal li a, .pagination-teal .pagination li a {
  background: #F3F8F8;
  border: none !important;
  color: #333333;
  display: inline-block;
  margin-right: 1px;
}

.pagination.pagination-teal li a:hover, .pagination-teal .pagination li a a:hover {
  background: #D8E4E7;
  color: #222222;
  cursor: pointer;
}

.pagination.pagination-teal li:first-child a, .pagination-teal .pagination li:first-child a {
  border: none !important;
}

.pagination.pagination-teal li.disabled a, .pagination-teal .pagination li.disabled a {
  color: #AAAAAA !important;
}

.pagination.pagination-teal li.disabled a:hover, .pagination-teal .pagination li.disabled a:hover, .pagination.pagination-teal li.disabled a:focus, .pagination-teal .pagination li.disabled a:focus {
  background: #F3F8F8;
  cursor: default !important;
}

.pagination.pagination-teal li.active a, .pagination-teal .pagination li.active a {
  background: #569099 !important;
  border: none !important;
  color: #FFFFFF !important;
  cursor: default !important;
  opacity: 1;
}

.pagination-teal li [class^=fa-], .pagination-teal li [class*=" fa-"] {
  color: #4D8189;
}

.pagination-teal li.disabled [class^=fa-], .pagination-teal li.disabled [class*=" fa-"] {
  opacity: 0.5;
}

.pagination.pagination-green li a, .pagination-green .pagination li a {
  background: #EFFBEC;
  border: none !important;
  color: #333333;
  display: inline-block;
  margin-right: 1px;
}

.pagination.pagination-green li a:hover, .pagination-green .pagination li a a:hover {
  background: #CEF3C5;
  color: #222222;
  cursor: pointer;
}

.pagination.pagination-green li:first-child a, .pagination-green .pagination li:first-child a {
  border: none !important;
}

.pagination.pagination-green li.disabled a, .pagination-green .pagination li.disabled a {
  color: #AAAAAA !important;
}

.pagination.pagination-green li.disabled a:hover, .pagination-green .pagination li.disabled a:hover, .pagination.pagination-green li.disabled a:focus, .pagination-green .pagination li.disabled a:focus {
  background: #EFFBEC;
  cursor: default !important;
}

.pagination.pagination-green li.active a, .pagination-green .pagination li.active a {
  background: #3D9400 !important;
  border: none !important;
  color: #FFFFFF !important;
  cursor: default !important;
  opacity: 1;
}

.pagination-green [class^=fa-], .pagination-green [class*=" fa-"] {
  color: #327B00;
}

.pagination-green li.disabled [class^=fa-], .pagination-green li.disabled [class*=" fa-"] {
  opacity: 0.5;
}

.pagination.pagination-bricky li a {
  background: #FDF8F7;
  border: none !important;
  color: #333333;
  display: inline-block;
  margin-right: 0;
}

.pagination.pagination-bricky li a:hover {
  background: #FBEEEC;
  color: #222222;
  cursor: pointer;
}

.pagination.pagination-bricky li a {
  margin-right: 1px;
}

.pagination.pagination-bricky li:first-child a {
  border: none !important;
}

.pagination.pagination-bricky li.disabled a {
  color: #AAAAAA !important;
}

.pagination.pagination-bricky li.disabled a:hover, .pagination-bricky .pagination li.disabled a:hover, .pagination.pagination-bricky li.disabled a:focus, .pagination-bricky .pagination li.disabled a:focus {
  background: #FDF8F7;
  cursor: default !important;
}

.pagination.pagination-bricky li.active a {
  background: #C83A2A !important;
  border: none !important;
  color: #FFFFFF !important;
  cursor: default !important;
  opacity: 1;
}

.pagination.pagination-bricky [class^=fa-], .pagination.pagination-bricky [class*=" fa-"] {
  color: #C83A2A;
}

.pagination.pagination-bricky li.disabled [class^=fa-], .pagination.pagination-bricky li.disabled [class*=" fa-"] {
  opacity: 0.5;
}

/* ---------------------------------------------------------------------- */
/*	ToDo List
 /* ---------------------------------------------------------------------- */
.todo {
  list-style: none;
  margin: 0;
  padding: 0;
}

.todo li a {
  background: white;
  border-bottom: 1px solid #E4E6EB;
  font-size: 12px;
  margin-left: 0 !important;
  padding: 10px 10px 10px 35px !important;
  position: relative;
  display: block;
  color: #333333;
}

.todo li .todo-actions:hover, .todo li .todo-actions:focus {
  text-decoration: none;
  background-color: #F4F6F9 !important;
}

.todo li .todo-actions i {
  color: #C7CBD5;
  font-size: 18px;
  margin: 0 5px 0 0;
  position: absolute;
  left: 10px;
}

.todo li .label {
  position: absolute;
  right: 10px;
  padding: 6px;
}

/* ---------------------------------------------------------------------- */
/*	Activities
 /* ---------------------------------------------------------------------- */
.activities {
  list-style: none outside none;
  margin: 0;
  padding: 0;
}

.activities li a {
  background: white;
  border-bottom: 1px solid #E4E6EB;
  font-size: 12px;
  margin-left: 0 !important;
  padding: 10px 100px 10px 10px !important;
  position: relative;
  display: block;
  color: #333333;
}

.activities li .activity:hover, .activities li .activity:focus {
  text-decoration: none;
  background-color: #F4F6F9 !important;
}

.activities li .activity .circle-icon {
  font-size: 16px !important;
  width: 50px;
  height: 50px;
  line-height: 50px;
  margin-right: 10px;
}

.activities li .activity img {
  margin-right: 10px;
}

.activities li .time {
  position: absolute;
  right: 10px;
  top: 30px;
  color: #CCCCCC;
}

/* ---------------------------------------------------------------------- */
/*	Main Container and main content elements
 /* ---------------------------------------------------------------------- */
.main-container {
  margin-top: 48px;
}

.header-default .main-container {
  margin-top: 0 !important;
}

.main-content {
  margin-top: 0;
  background-color: #FFFFFF;
  padding: 0;
}

.container {
  max-width: none !important;
  width: auto !important;
}

.footer-fixed .main-content .container {
  margin-bottom: 22px;
}

/* ---------------------------------------------------------------------- */
/*	Main Navigation
 /* ---------------------------------------------------------------------- */
.main-navigation {
  padding: 0;
  max-height: none !important;
  z-index: 1000;
}

ul.main-navigation-menu {
  list-style: none;
  margin: 0;
  padding: 0;
}

.sidebar-fixed .wrap-menu {
  overflow: hidden;
  position: fixed;
  width: 225px;
  height: 300px;
}

ul.main-navigation-menu > li {
  border: 0 none;
  display: block;
  margin: 0;
  padding: 0;
}

ul.main-navigation-menu > li > a {
  border: 0 none;
  display: block;
  font-size: 14px;
  font-weight: 300;
  margin: 0;
  padding: 10px 15px;
  position: relative;
  text-decoration: none;
  border-bottom: 1px solid #DDDDDD;
  border-top: 1px solid #FFFFFF;
}

ul.main-navigation-menu li a .badge {
  float: right;
  margin-right: 13px;
  margin-top: 1px;
}

ul.main-navigation-menu > li:last-child > a, ul.main-navigation-menu > li.last > a {
  border-bottom-color: transparent !important;
}

ul.main-navigation-menu > li > a i {
  font-size: 16px;
  margin-right: 5px;
  text-shadow: none;
}

ul.main-navigation-menu li a .icon-arrow {
  float: right;
  margin-right: 6px;
  margin-top: 2px;
}

ul.main-navigation-menu li > a > .icon-arrow:before {
  content: "\e163";
  font-family: "clip-font";
}

ul.main-navigation-menu li.open > a > .icon-arrow:before {
  content: "\e164";
}

ul.main-navigation-menu > li > a:hover .icon-arrow, ul.main-navigation-menu > li.open > a .icon-arrow {
  opacity: 1;
}

/* 2nd level sub menu */
ul.main-navigation-menu li > ul.sub-menu {
  clear: both;
  list-style: none;
  margin: 8px 0;
  display: none;
  padding: 0;
}

ul.main-navigation-menu li > ul.sub-menu > li {
  background: none;
  margin-bottom: 0;
  margin-left: 0;
  margin-right: 0;
  margin-top: 1px !important;
  padding: 0;
}

ul.main-navigation-menu li > ul.sub-menu > li > a {
  background: none;
  display: block;
  font-size: 14px;
  font-weight: 300;
  margin: 0;
  padding-bottom: 5px;
  padding-left: 44px !important;
  padding-right: 0;
  padding-top: 5px;
  text-decoration: none;
}

ul.main-navigation-menu > li > ul.sub-menu li > a .icon-arrow {
  margin-right: 20px;
}

/* 3rd level sub menu */
ul.main-navigation-menu > li > ul.sub-menu li > ul.sub-menu {
  margin: 0;
}

ul.main-navigation-menu li.active > ul.sub-menu {
  display: block;
  padding: 0;
}

ul.main-navigation-menu > li > ul.sub-menu > li > ul.sub-menu > li > a {
  padding-left: 60px !important;
}

ul.main-navigation-menu > li > ul.sub-menu > li > ul.sub-menu > li > ul.sub-menu > li > a {
  padding-left: 80px !important;
}

/*navigation toggler*/
.navigation-toggler {
  cursor: pointer;
  height: 30px;
  margin-left: 175px;
  margin-top: 10px;
  margin-bottom: 10px;
  width: 30px;
  line-height: 30px;
  text-align: center;
}

/* ---------------------------------------------------------------------- */
/*	Footer and footer elemnts
 /* ---------------------------------------------------------------------- */
.footer {
  font-size: 12px;
  padding: 8px 20px 5px;
  position: relative;
}

.footer-inner {
  float: left;
}

.footer-items {
  float: right;
}

.footer-fixed .footer {
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  z-index: 10000;
  border-top-width: 1px;
  border-top-style: solid;
}

/* ---------------------------------------------------------------------- */
/*	Breadcrumb
 /* ---------------------------------------------------------------------- */
.breadcrumb {
  margin: 0 -15px;
  border-radius: 0;
  padding: 15px 45px 15px 15px;
  border-bottom: 1px solid #C8C7CC;
  position: relative;
  height: 51px;
}

.breadcrumb i {
  color: #cccccc;
  font-size: 16px;
}

/* ---------------------------------------------------------------------- */
/*	Rating
 /* ---------------------------------------------------------------------- */
.rating {
  direction: rtl;
  font-size: 30px;
  unicode-bidi: bidi-override;
}

.rating span.star, .rating span.star {
  display: inline-block;
  font-family: FontAwesome;
  font-style: normal;
  font-weight: normal;
}

.rating span.star:hover, .rating span.star:hover {
  cursor: pointer;
}

.rating span.star:before, .rating span.star:before {
  color: #999999;
  content: "\f006";
  padding-right: 5px;
}

.rating span.star:hover:before, .rating span.star:hover:before, .rating span.star:hover ~ span.star:before, .rating span.star:hover ~ span.star:before {
  color: #E3CF7A;
  content: "\f005";
}

/* ---------------------------------------------------------------------- */
/*	Dropdown elements
 /* ---------------------------------------------------------------------- */
.dropdown-checkboxes {
  padding: 5px;
}

.dropdown-checkboxes label {
  display: block;
  font-weight: normal;
  line-height: 20px;
}

/* ---------------------------------------------------------------------- */
/*	Labels and Badges
 /* ---------------------------------------------------------------------- */
.badge-success {
  background-color: #5CB85C;
}

.badge-success[href]:hover, .badge-success[href]:focus {
  background-color: #449D44;
}

.badge-warning {
  background-color: #F0AD4E;
}

.badge-warning[href]:hover, .badge-warning[href]:focus {
  background-color: #EC971F;
}

.badge-info {
  background-color: #5BC0DE;
}

.badge-info[href]:hover, .badge-info[href]:focus {
  background-color: #31B0D5;
}

.badge-danger {
  background-color: #D9534F;
}

.badge-danger[href]:hover, .badge-danger[href]:focus {
  background-color: #C9302C;
}

.badge-new {
  background-color: #007AFF;
  border-radius: 12px 12px 12px 12px !important;
  font-size: 11px !important;
  font-weight: 300;
  height: 18px;
  padding: 3px 6px;
  text-align: center;
  text-shadow: none !important;
  vertical-align: middle;
}

.label {
  font-size: 85% !important;
  padding: 0.4em 0.6em !important;
}

.label-inverse, .badge-inverse {
  background-color: #555555;
}

/* ---------------------------------------------------------------------- */
/*	Progress bar
 /* ---------------------------------------------------------------------- */
.progress.progress-xs {
  height: 7px;
}

.progress.progress-sm {
  height: 12px;
}

/* ---------------------------------------------------------------------- */
/*	Modal elements
 /* ---------------------------------------------------------------------- */
.modal-footer {
  background: #ECF0F1;
  border-radius: 0 0 6px 6px;
}

/* ---------------------------------------------------------------------- */
/*	Tabs
 /* ---------------------------------------------------------------------- */
.tabbable {
  margin-bottom: 15px;
}

.nav-tabs {
  border-color: #DDDDDD;
  margin-bottom: 0;
  margin-left: 0;
  position: relative;
  top: 1px;
}

.nav-tabs > li.active > a, .nav-tabs > li.active > a:hover, .nav-tabs > li.active > a:focus {
  background-color: #FFFFFF;
  border-color: #DDDDDD #DDDDDD transparent;
  border-top: 2px solid #DDDDDD;
  color: #333333;
  line-height: 16px;
  margin-top: -1px;
  z-index: 12;
}

.nav-tabs > li:first-child > a {
  margin-left: 0;
}

.nav-tabs > li > a:hover {
  background-color: #FFFFFF;
  border-color: #DDDDDD;
  color: #4C8FBD;
}

.nav-tabs > li > a, .nav-tabs > li > a:focus {
  background-color: #F9F9F9;
  border-color: #DDDDDD;
  border-radius: 0 0 0 0 !important;
  color: #999999;
  line-height: 16px;
  margin-right: -1px;
  position: relative;
}

.tab-content {
  border: 1px solid #DDDDDD;
  padding: 16px 12px;
  position: relative;
}

.tabs-below > .nav-tabs {
  border-bottom-width: 0;
  border-color: #DDDDDD;
  margin-bottom: 0;
  margin-top: -1px;
  top: auto;
}

.tabs-below > .nav-tabs > li > a, .tabs-below > .nav-tabs > li > a:hover, .tabs-below > .nav-tabs > li > a:focus {
  border-color: #DDDDDD;
}

.tabs-below > .nav-tabs > li.active > a, .tabs-below > .nav-tabs > li.active > a:hover, .tabs-below > .nav-tabs > li.active > a:focus {
  border-bottom: 2px solid #DDDDDD;
  border-color: transparent #DDDDDD #DDDDDD;
  border-top-width: 1px;
  margin-top: 0;
}

.tabs-left > .nav-tabs > li > a, .tabs-right > .nav-tabs > li > a {
  min-width: 60px;
}

.tabs-left > .nav-tabs {
  border-color: #DDDDDD;
  float: left;
  margin-bottom: 0;
  top: auto;
}

.tabs-left > .nav-tabs > li {
  float: none;
}

.tabs-left > .nav-tabs > li > a, .tabs-left > .nav-tabs > li > a:focus, .tabs-left > .nav-tabs > li > a:hover {
  border-color: #DDDDDD;
  margin: 0 -1px 0 0;
}

.tabs-left > .nav-tabs > li.active > a, .tabs-left > .nav-tabs > li.active > a:focus, .tabs-left > .nav-tabs > li.active > a:hover {
  border-color: #DDDDDD transparent #DDDDDD #DDDDDD;
  border-left: 2px solid #DDDDDD;
  border-top-width: 1px;
  margin: 0 -1px;
}

.tabs-right > .nav-tabs {
  border-color: #DDDDDD;
  float: right;
  margin-bottom: 0;
  top: auto;
}

.tabs-right > .nav-tabs > li {
  float: none;
}

.tabs-right > .nav-tabs > li > a, .tabs-right > .nav-tabs > li > a:focus, .tabs-right > .nav-tabs > li > a:hover {
  border-color: #DDDDDD;
  margin: 0 -1px;
}

.tabs-right > .nav-tabs > li.active > a, .tabs-right > .nav-tabs > li.active > a:focus, .tabs-right > .nav-tabs > li.active > a:hover {
  border-color: #DDDDDD #DDDDDD #DDDDDD transparent;
  border-right: 2px solid #DDDDDD;
  border-top-width: 1px;
  margin: 0 -2px 0 -1px;
}

.nav-tabs > li > a > .badge {
  line-height: 8px;
  opacity: 0.7;
}

.nav-tabs > li > a > [class*=fa-] {
  opacity: 0.75;
}

.nav-tabs > li.active > a > .badge, .nav-tabs > li.active > a > [class*=fa-] {
  opacity: 1;
}

.nav-tabs li [class*=" fa-"], .nav-tabs li [class^=fa-] {
  display: inline-block;
  text-align: center;
  width: 1.25em;
}

.nav-tabs > li.open .dropdown-toggle {
  background-color: #4F99C6;
  border-color: #4F99C6;
  color: #FFFFFF;
}

.nav-tabs > li.open .dropdown-toggle > [class*=fa-] {
  color: #FFFFFF !important;
  opacity: 1;
}

.tabs-left .tab-content, .tabs-right .tab-content {
  overflow: auto;
}

/*teal*/
.tab-teal > li.active > a, .tab-teal > li.active > a:hover, .tab-teal > li.active > a:focus {
  border-color: #569099 #DDDDDD transparent;
  border-top: 2px solid #569099;
}

.tab-teal > li > a:hover {
  color: #569099;
}

.tab-teal > li.dropdown.open.active > a:hover, .tab-teal > li.dropdown.open.active > a:focus {
  background-color: #569099;
  border-color: #569099;
  color: #FFFFFF;
}

.tab-teal > li.open .dropdown-toggle {
  background-color: #569099;
  border-color: #569099;
  color: #FFFFFF;
}

.tab-teal .dropdown-menu > li > a:hover, .tab-teal .dropdown-menu > li > a:focus {
  background-color: #569099;
}

.tab-teal .active > a, .tab-teal .active > a:hover, .tab-teal .active > a:focus {
  background-color: #569099;
}

.tabs-left > .tab-teal > li.active > a, .tabs-left > .tab-teal > li.active > a:focus, .tabs-left > .tab-teal > li.active > a:hover {
  border-color: #DDDDDD transparent #DDDDDD #569099;
  border-left: 2px solid #569099;
}

.tabs-below > .tab-teal > li.active > a, .tabs-below > .tab-teal > li.active > a:hover, .tabs-below > .tab-teal > li.active > a:focus {
  border-bottom: 2px solid #569099;
  border-color: transparent #DDDDDD #569099;
}

.tabs-right > .tab-teal > li.active > a, .tabs-right > .tab-teal > li.active > a:focus, .tabs-right > .tab-teal > li.active > a:hover {
  border-color: #DDDDDD #569099 #DDDDDD transparent;
  border-right: 2px solid #569099;
}

.tab-padding.tab-teal > li > a, .tab-padding.tab-teal > li > a:focus, .tab-padding.tab-teal > li > a:hover {
  border-color: rgba(0, 0, 0, 0);
  color: #FFFFFF;
}

.tab-padding.tab-teal > li:not(.active) > a:hover {
  border-color: rgba(0, 0, 0, 0.15) rgba(0, 0, 0, 0.15) transparent;
  opacity: 0.85;
}

.tab-padding.tab-teal > li:not(.active) > a > [class*=fa-]:first-child {
  color: #FFFFFF !important;
}

.tab-padding.tab-teal > li:not(.active) > a > .badge {
  background-color: #FFFFFF !important;
  color: rgba(0, 0, 0, 0.4) !important;
}

.tab-padding.tab-teal > li > a, .tab-padding.tab-teal > li > a:focus {
  background-color: #7DAFB7;
}

.tab-padding.tab-teal > li.active > a, .tab-padding.tab-teal > li.active > a:focus, .tab-padding.tab-teal > li.active > a:hover {
  background-color: #FFFFFF;
  box-shadow: none;
  color: #4F80A0;
}

.tab-padding.tab-teal > li.active > a, .tab-padding.tab-teal > li.active > a:focus, .tab-padding.tab-teal > li.active > a:hover {
  border-color: #569099 #DDDDDD transparent;
  color: #4D8189;
}

.tabs-below .tab-padding.tab-teal > li.active > a {
  border-color: transparent #7DB4D8 #7DB4D8;
}

.tab-padding.tab-teal {
  border-bottom-color: #569099;
  background-color: #F3F8F8;
}

.tabs-below .nav-tabs.tab-teal {
  padding-bottom: 6px;
  border-bottom-color: #DDDDDD;
  border-top: 0 none;
}

/*bricky*/
.tab-bricky > li.active > a, .tab-bricky > li.active > a:hover, .tab-bricky > li.active > a:focus {
  border-color: #C83A2A #DDDDDD transparent;
  border-top: 2px solid #C83A2A;
}

.tab-bricky > li > a:hover {
  color: #C83A2A;
}

.tab-bricky > li.dropdown.open.active > a:hover, .tab-bricky > li.dropdown.open.active > a:focus {
  background-color: #C83A2A;
  border-color: #C83A2A;
  color: #FFFFFF;
}

.tab-bricky > li.open .dropdown-toggle {
  background-color: #C83A2A;
  border-color: #C83A2A;
  color: #FFFFFF;
}

.tab-bricky .dropdown-menu > li > a:hover, .tab-bricky .dropdown-menu > li > a:focus {
  background-color: #C83A2A;
}

.tab-bricky .active > a, .tab-bricky .active > a:hover, .tab-bricky .active > a:focus {
  background-color: #C83A2A;
}

.tabs-left > .tab-bricky > li.active > a, .tabs-left > .tab-bricky > li.active > a:focus, .tabs-left > .tab-bricky > li.active > a:hover {
  border-color: #DDDDDD transparent #DDDDDD #C83A2A;
  border-left: 2px solid #C83A2A;
}

.tabs-below > .tab-bricky > li.active > a, .tabs-below > .tab-bricky > li.active > a:hover, .tabs-below > .tab-bricky > li.active > a:focus {
  border-bottom: 2px solid #C83A2A;
  border-color: transparent #DDDDDD #C83A2A;
}

.tabs-right > .tab-bricky > li.active > a, .tabs-right > .tab-bricky > li.active > a:focus, .tabs-right > .tab-bricky > li.active > a:hover {
  border-color: #DDDDDD #C83A2A #DDDDDD transparent;
  border-right: 2px solid #C83A2A;
}

.tab-padding.tab-bricky > li > a, .tab-padding.tab-bricky > li > a:focus, .tab-padding.tab-bricky > li > a:hover {
  border-color: rgba(0, 0, 0, 0);
  color: #FFFFFF;
}

.tab-padding.tab-bricky > li:not(.active) > a:hover {
  border-color: rgba(0, 0, 0, 0.15) rgba(0, 0, 0, 0.15) transparent;
  opacity: 0.85;
}

.tab-padding.tab-bricky > li:not(.active) > a > [class*=fa-]:first-child {
  color: #FFFFFF !important;
}

.tab-padding.tab-bricky > li:not(.active) > a > .badge {
  background-color: #FFFFFF !important;
  color: rgba(0, 0, 0, 0.4) !important;
}

.tab-padding.tab-bricky > li > a, .tab-padding.tab-bricky > li > a:focus {
  background-color: #DC6456;
}

.tab-padding.tab-bricky > li.active > a, .tab-padding.tab-bricky > li.active > a:focus, .tab-padding.tab-bricky > li.active > a:hover {
  background-color: #FFFFFF;
  box-shadow: none;
  color: #333333;
}

.tab-padding.tab-bricky > li.active > a, .tab-padding.tab-bricky > li.active > a:focus, .tab-padding.tab-teal > li.active > a:hover {
  border-color: #C83A2A #DDDDDD transparent;
  color: #333333;
}

.tabs-below .tab-padding.tab-bricky > li.active > a {
  border-color: transparent #C83A2A #DDDDDD;
}

.tab-padding.tab-bricky {
  border-bottom-color: #C83A2A;
  background-color: #FBEEEC;
}

.tabs-below .nav-tabs.tab-bricky {
  padding-bottom: 6px;
  border-bottom-color: #DDDDDD;
  border-top: 0 none;
}

/*green*/
.tab-green > li.active > a, .tab-green > li.active > a:hover, .tab-green > li.active > a:focus {
  border-color: #3D9400 #DDDDDD transparent;
  border-top: 2px solid #3D9400;
}

.tab-green > li > a:hover {
  color: #3D9400;
}

.tab-green > li.dropdown.open.active > a:hover, .tab-green > li.dropdown.open.active > a:focus {
  background-color: #3D9400;
  border-color: #3D9400;
  color: #FFFFFF;
}

.tab-green > li.open .dropdown-toggle {
  background-color: #3D9400;
  border-color: #3D9400;
  color: #FFFFFF;
}

.tab-green .dropdown-menu > li > a:hover, .tab-green .dropdown-menu > li > a:focus {
  background-color: #3D9400;
}

.tab-green .active > a, .tab-green .active > a:hover, .tab-green .active > a:focus {
  background-color: #3D9400;
}

.tabs-left > .tab-green > li.active > a, .tabs-left > .tab-green > li.active > a:focus, .tabs-left > .tab-green > li.active > a:hover {
  border-color: #DDDDDD transparent #DDDDDD #3D9400;
  border-left: 2px solid #3D9400;
}

.tabs-below > .tab-green > li.active > a, .tabs-below > .tab-green > li.active > a:hover, .tabs-below > .tab-green > li.active > a:focus {
  border-bottom: 2px solid #3D9400;
  border-color: transparent #DDDDDD #3D9400;
}

.tabs-right > .tab-green > li.active > a, .tabs-right > .tab-green > li.active > a:focus, .tabs-right > .tab-green > li.active > a:hover {
  border-color: #DDDDDD #3D9400 #DDDDDD transparent;
  border-right: 2px solid #3D9400;
}

.tab-padding.tab-green > li > a, .tab-padding.tab-green > li > a:focus, .tab-padding.tab-green > li > a:hover {
  border-color: rgba(0, 0, 0, 0);
  color: #FFFFFF;
}

.tab-padding.tab-green > li:not(.active) > a:hover {
  border-color: rgba(0, 0, 0, 0.15) rgba(0, 0, 0, 0.15) transparent;
  opacity: 0.85;
}

.tab-padding.tab-green > li:not(.active) > a > [class*=fa-]:first-child {
  color: #FFFFFF !important;
}

.tab-padding.tab-green > li:not(.active) > a > .badge {
  background-color: #FFFFFF !important;
  color: rgba(0, 0, 0, 0.4) !important;
}

.tab-padding.tab-green > li > a, .tab-padding.tab-green > li > a:focus {
  background-color: #71B145;
}

/*purple*/
.tab-purple > li.active > a, .tab-purple > li.active > a:hover, .tab-purple > li.active > a:focus {
  border-color: #57517B #DDDDDD transparent;
  border-top: 2px solid #57517B;
}

.tab-purple > li > a:hover {
  color: #57517B;
}

.tab-purple > li.dropdown.open.active > a:hover, .tab-purple > li.dropdown.open.active > a:focus {
  background-color: #57517B;
  border-color: #57517B;
  color: #FFFFFF;
}

.tab-purple > li.open .dropdown-toggle {
  background-color: #57517B;
  border-color: #57517B;
  color: #FFFFFF;
}

.tab-purple .dropdown-menu > li > a:hover, .tab-purple .dropdown-menu > li > a:focus {
  background-color: #57517B;
}

.tab-purple .active > a, .tab-purple .active > a:hover, .tab-purple .active > a:focus {
  background-color: #57517B;
}

.tabs-left > .tab-purple > li.active > a, .tabs-left > .tab-purple > li.active > a:focus, .tabs-left > .tab-purple > li.active > a:hover {
  border-color: #DDDDDD transparent #DDDDDD #57517B;
  border-left: 2px solid #57517B;
}

.tabs-below > .tab-purple > li.active > a, .tabs-below > .tab-purple > li.active > a:hover, .tabs-below > .tab-purple > li.active > a:focus {
  border-bottom: 2px solid #57517B;
  border-color: transparent #DDDDDD #57517B;
}

.tabs-right > .tab-purple > li.active > a, .tabs-right > .tab-purple > li.active > a:focus, .tabs-right > .tab-purple > li.active > a:hover {
  border-color: #DDDDDD #57517B #DDDDDD transparent;
  border-right: 2px solid #57517B;
}

.tab-padding.tab-purple > li > a, .tab-padding.tab-purple > li > a:focus, .tab-padding.tab-purple > li > a:hover {
  border-color: rgba(0, 0, 0, 0);
  color: #FFFFFF;
}

.tab-padding.tab-purple > li:not(.active) > a:hover {
  border-color: rgba(0, 0, 0, 0.15) rgba(0, 0, 0, 0.15) transparent;
  opacity: 0.85;
}

.tab-padding.tab-purple > li:not(.active) > a > [class*=fa-]:first-child {
  color: #FFFFFF !important;
}

.tab-padding.tab-purple > li:not(.active) > a > .badge {
  background-color: #FFFFFF !important;
  color: rgba(0, 0, 0, 0.4) !important;
}

.tab-padding.tab-purple > li > a, .tab-padding.tab-purple > li > a:focus {
  background-color: #84809F;
}

.tab-padding.tab-purple > li.active > a, .tab-padding.tab-purple > li.active > a:focus, .tab-padding.tab-purple > li.active > a:hover {
  background-color: #FFFFFF;
  box-shadow: none;
  color: #333333;
}

.tab-padding.tab-purple > li.active > a, .tab-padding.tab-purple > li.active > a:focus, .tab-padding.tab-teal > li.active > a:hover {
  border-color: #57517B #DDDDDD transparent;
  color: #333333;
}

.tabs-below .tab-padding.tab-purple > li.active > a {
  border-color: transparent #57517B #DDDDDD;
}

.tab-padding.tab-purple {
  border-bottom-color: #57517B;
  background-color: #F5F4F9;
}

.tabs-below .nav-tabs.tab-purple {
  padding-bottom: 6px;
  border-bottom-color: #DDDDDD;
  border-top: 0 none;
}

.tab-padding.tab-green > li.active > a, .tab-padding.tab-green > li.active > a:focus, .tab-padding.tab-green > li.active > a:hover {
  background-color: #FFFFFF;
  box-shadow: none;
  color: #333333;
}

.tab-padding.tab-green > li.active > a, .tab-padding.tab-green > li.active > a:focus, .tab-padding.tab-teal > li.active > a:hover {
  border-color: #3D9400 #DDDDDD transparent;
  color: #333333;
}

.tabs-below .tab-padding.tab-green > li.active > a {
  border-color: transparent #3D9400 #DDDDDD;
}

.tab-padding.tab-green {
  border-bottom-color: #3D9400;
  background-color: #EFFBEC;
}

.tabs-below .nav-tabs.tab-green {
  padding-bottom: 6px;
  border-bottom-color: #DDDDDD;
  border-top: 0 none;
}

/*blue*/
.tab-blue > li.active > a, .tab-blue > li.active > a:hover, .tab-blue > li.active > a:focus {
  border-color: #364F6A #DDDDDD transparent;
  border-top: 2px solid #364F6A;
}

.tab-blue > li > a:hover {
  color: #364F6A;
}

.tab-blue > li.dropdown.open.active > a:hover, .tab-blue > li.dropdown.open.active > a:focus {
  background-color: #364F6A;
  border-color: #364F6A;
  color: #FFFFFF;
}

.tab-blue > li.open .dropdown-toggle {
  background-color: #364F6A;
  border-color: #364F6A;
  color: #FFFFFF;
}

.tab-blue .dropdown-menu > li > a:hover, .tab-blue .dropdown-menu > li > a:focus {
  background-color: #364F6A;
}

.tab-blue .active > a, .tab-blue .active > a:hover, .tab-blue .active > a:focus {
  background-color: #364F6A;
}

.tabs-left > .tab-blue > li.active > a, .tabs-left > .tab-blue > li.active > a:focus, .tabs-left > .tab-blue > li.active > a:hover {
  border-color: #DDDDDD transparent #DDDDDD #364F6A;
  border-left: 2px solid #364F6A;
}

.tabs-below > .tab-blue > li.active > a, .tabs-below > .tab-blue > li.active > a:hover, .tabs-below > .tab-blue > li.active > a:focus {
  border-bottom: 2px solid #364F6A;
  border-color: transparent #DDDDDD #364F6A;
}

.tabs-right > .tab-blue > li.active > a, .tabs-right > .tab-blue > li.active > a:focus, .tabs-right > .tab-blue > li.active > a:hover {
  border-color: #DDDDDD #364F6A #DDDDDD transparent;
  border-right: 2px solid #364F6A;
}

.tab-padding.tab-blue > li > a, .tab-padding.tab-blue > li > a:focus, .tab-padding.tab-blue > li > a:hover {
  border-color: rgba(0, 0, 0, 0);
  color: #FFFFFF;
}

.tab-padding.tab-blue > li:not(.active) > a:hover {
  border-color: rgba(0, 0, 0, 0.15) rgba(0, 0, 0, 0.15) transparent;
  opacity: 0.85;
}

.tab-padding.tab-blue > li:not(.active) > a > [class*=fa-]:first-child {
  color: #FFFFFF !important;
}

.tab-padding.tab-blue > li:not(.active) > a > .badge {
  background-color: #FFFFFF !important;
  color: rgba(0, 0, 0, 0.4) !important;
}

.tab-padding.tab-blue > li > a, .tab-padding.tab-blue > li > a:focus {
  background-color: #6C7F92;
}

.tab-padding.tab-blue > li.active > a, .tab-padding.tab-blue > li.active > a:focus, .tab-padding.tab-blue > li.active > a:hover {
  background-color: #FFFFFF;
  box-shadow: none;
  color: #333333;
}

.tab-padding.tab-blue > li.active > a, .tab-padding.tab-blue > li.active > a:focus, .tab-padding.tab-teal > li.active > a:hover {
  border-color: #364F6A #DDDDDD transparent;
  color: #333333;
}

.tabs-below .tab-padding.tab-blue > li.active > a {
  border-color: transparent #364F6A #DDDDDD;
}

.tab-padding.tab-blue {
  border-bottom-color: #364F6A;
  background-color: #F3F5F6;
}

.tabs-below .nav-tabs.tab-blue {
  padding-bottom: 6px;
  border-bottom-color: #DDDDDD;
  border-top: 0 none;
}

.nav > li.active > .dropdown-toggle {
  cursor: pointer !important;
}

.nav-pills .open .dropdown-toggle > [class*=fa-], .nav > li.dropdown.open.active > a:hover > [class*=fa-], .nav > li.dropdown.open.active > a:focus > [class*=fa-] {
  color: #FFFFFF !important;
}

.nav-tabs > li:not(.active):not(.open) > a:not(:hover) > .disabled[class*=fa-]:first-child {
  color: #909090 !important;
}

.nav-tabs.tab-space-1 > li > a {
  margin-right: 1px;
}

.nav-tabs.tab-space-2 > li > a {
  margin-right: 2px;
}

.nav-tabs.tab-space-3 > li > a {
  margin-right: 3px;
}

.nav-tabs.tab-space-4 > li > a {
  margin-right: 4px;
}

.nav-tabs.tab-padding {
  padding: 6px 5px 0;
  border: 1px solid #DDDDDD;
}

.panel-tabs .nav-tabs {
  float: right;
  margin-top: -52px;
  border: none;
}

.panel-tabs .nav-tabs > li > a {
  height: 36px;
  border-top: none;
  border-bottom: none;
  background: none;
  border-right: 1px solid #CDCDCD;
  box-shadow: 1px 1px 0 0 #FFFFFF;
}

.panel-tabs .nav-tabs > li > a:hover {
  background: #ffffff;
}

.panel-tabs .nav-tabs > li:first-child > a {
  box-shadow: 0 1px 0 1px #FFFFFF;
}

.panel-tabs .nav-tabs > li.active > a, .panel-tabs .nav-tabs > li.active > a:hover, .panel-tabs .nav-tabs > li.active > a:focus {
  background-color: #FFFFFF;
  background-image: none;
  border-color: #4C8FBD #DDDDDD transparent;
  border-top: none;
  box-shadow: none;
  color: #333333;
  line-height: 16px;
  margin-top: 0;
}

.panel-tabs .tab-content {
  border: none;
  padding: 0;
}

/* ---------------------------------------------------------------------- */
/*	Page Header
 /* ---------------------------------------------------------------------- */
.page-header {
  margin: 20px 0 20px;
}

.page-header h1 {
  font-family: "Raleway", sans-serif;
  font-weight: 200;
  letter-spacing: -1px;
  margin: 0 0 15px;
  padding: 0;
  color: #666666;
}

.page-header h1 small {
  color: #888888;
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 0;
}

/* ---------------------------------------------------------------------- */
/*	Accordion
 /* ---------------------------------------------------------------------- */
.accordion-custom .panel {
  background-color: #FFFFFF;
  border: none;
  border-radius: 0 0 0 0;
  box-shadow: none;
}

.accordion-custom .panel-heading {
  padding: 0;
  background-image: none;
  height: auto;
  box-shadow: none;
  border-radius: 0;
}

.accordion-custom .panel-body {
  border: none !important;
}

.accordion-custom .panel-heading .accordion-toggle {
  background-color: #cccccc;
  color: #606060;
  display: block;
  font-size: 13px;
  font-weight: bold;
  line-height: 1;
  padding: 10px;
  position: relative;
}

.accordion-custom .panel-heading .accordion-toggle:hover, .accordion-custom .panel-heading .accordion-toggle:focus {
  text-decoration: none;
}

.accordion-custom .panel-heading .accordion-toggle.collapsed {
  background-color: #F3F3F3;
  border-color: #D9D9D9;
  border-width: 0 0 0 1px;
  color: #606060;
  font-weight: normal;
}

.accordion-custom .panel-heading .accordion-toggle > .icon-arrow:before {
  content: "\f078";
}

.accordion-custom .panel-heading .accordion-toggle.collapsed > .icon-arrow:before {
  content: "\f054" !important;
}

.accordion-custom.panel-group .panel-heading .accordion-toggle > .icon-arrow {
  font-size: 11px;
  padding: 0 5px;
}

.accordion-custom.panel-group .panel-heading .accordion-toggle.collapsed > .icon-arrow {
  opacity: 0.75;
}

.accordion-teal .panel-heading .accordion-toggle {
  background: #F3F7F8;
  border-left: 2px solid #569099;
  color: #437278;
}

.accordion-teal .panel-heading .accordion-toggle.collapsed {
  background: #F3F7F8;
  border-left: none;
}

/* ---------------------------------------------------------------------- */
/*	Social Icons
 /* ---------------------------------------------------------------------- */
.social-icons li a, a.social-icon {
  background-position: 0 0;
  background-repeat: no-repeat;
  display: block;
  height: 28px;
  transition: all 0.3s ease-in-out 0s;
  width: 28px;
}

/* ---------------------------------------------------------------------- */
/*	Sliders
 /* ---------------------------------------------------------------------- */
[class^=slider-] .ui-rangeSlider-bar {
  height: 18px !important;
}

[class^=slider-] .ui-rangeSlider-label {
  padding: 3px 6px !important;
  bottom: 25px !important;
  background: none !important;
}

[class^=slider-] .ui-rangeSlider-label:after {
  content: "\f0d7";
  display: inline;
  float: right;
  font-family: FontAwesome;
  font-size: 16px;
  margin: 0 -6px;
  width: 100%;
  bottom: -14px;
  position: absolute;
  text-align: center;
  text-shadow: none;
}

[class^=slider-] .ui-rangeSlider-arrow {
  height: 14px !important;
  margin: 3px 0 !important;
  font-size: 20px;
  line-height: 14px;
  cursor: pointer;
}

[class^=slider-] .ui-rangeSlider-arrow.ui-rangeSlider-leftArrow {
  background-image: none !important;
}

[class^=slider-] .ui-rangeSlider-arrow.ui-rangeSlider-leftArrow:before {
  content: "\f0d9";
  display: inline;
  float: right;
  font-family: FontAwesome;
  margin-right: 5px;
}

[class^=slider-] .ui-rangeSlider-arrow.ui-rangeSlider-rightArrow {
  background-image: none !important;
}

[class^=slider-] .ui-rangeSlider-arrow.ui-rangeSlider-rightArrow:before {
  content: "\f0da";
  display: inline;
  float: right;
  font-family: FontAwesome;
  margin-right: 5px;
}

[class^=slider-] .ui-rangeSlider-innerBar {
  height: 14px !important;
}

[class^=slider-] .ui-rangeSlider-handle {
  height: 20px !important;
}

[class^=slider-].ui-rangeSlider-disabled {
  opacity: 0.5;
}

.slider-teal .ui-rangeSlider-bar {
  background: rgba(86, 144, 153, 0.6) !important;
}

.slider-teal .ui-rangeSlider-handle {
  background: rgba(86, 144, 153, 0.8) !important;
}

.slider-teal .ui-rangeSlider-label {
  background-image: none !important;
  background-color: #569099 !important;
  color: #FFFFFF !important;
  text-shadow: 0 1px 0 #000000;
}

.slider-teal .ui-rangeSlider-label:after {
  color: #569099 !important;
}

.slider-teal .ui-rangeSlider-arrow {
  color: #4D8189;
}

.slider-bricky .ui-rangeSlider-bar {
  background: rgba(200, 58, 42, 0.6) !important;
}

.slider-bricky .ui-rangeSlider-handle {
  background: rgba(200, 58, 42, 0.8) !important;
}

.slider-bricky .ui-rangeSlider-label {
  background-image: none !important;
  background-color: #C83A2A !important;
  color: #FFFFFF !important;
  text-shadow: 0 1px 0 #000000;
}

.slider-bricky .ui-rangeSlider-label:after {
  color: #C83A2A !important;
}

.slider-bricky .ui-rangeSlider-arrow {
  color: #B33426;
}

.slider-purple .ui-rangeSlider-bar {
  background: rgba(87, 81, 123, 0.6) !important;
}

.slider-purple .ui-rangeSlider-handle {
  background: rgba(87, 81, 123, 0.8) !important;
}

.slider-purple .ui-rangeSlider-label {
  background-image: none !important;
  background-color: #57517B !important;
  color: #FFFFFF !important;
  text-shadow: 0 1px 0 #000000;
}

.slider-purple .ui-rangeSlider-label:after {
  color: #57517B !important;
}

.slider-purple .ui-rangeSlider-arrow {
  color: #413D5C;
}

.slider-green .ui-rangeSlider-bar {
  background: rgba(61, 148, 0, 0.6) !important;
}

.slider-green .ui-rangeSlider-handle {
  background: rgba(61, 148, 0, 0.8) !important;
}

.slider-green .ui-rangeSlider-label {
  background-image: none !important;
  background-color: #3D9400 !important;
  color: #FFFFFF !important;
  text-shadow: 0 1px 0 #000000;
}

.slider-green .ui-rangeSlider-label:after {
  color: #3D9400 !important;
}

.slider-green .ui-rangeSlider-arrow {
  color: #327B00;
}

.slider-yellow .ui-rangeSlider-bar {
  background: rgba(255, 167, 34, 0.6) !important;
}

.slider-yellow .ui-rangeSlider-handle {
  background: rgba(255, 167, 34, 0.8) !important;
}

.slider-yellow .ui-rangeSlider-label {
  background-image: none !important;
  background-color: #FFA722 !important;
  color: #FFFFFF !important;
  text-shadow: 0 1px 0 #482C00;
}

.slider-yellow .ui-rangeSlider-label:after {
  color: #FFB848 !important;
}

.slider-yellow .ui-rangeSlider-arrow {
  color: #FFAE2F;
}

.slider-blue .ui-rangeSlider-bar {
  background: rgba(54, 79, 106, 0.6) !important;
}

.slider-blue .ui-rangeSlider-handle {
  background: rgba(54, 79, 106, 0.8) !important;
}

.slider-blue .ui-rangeSlider-label {
  background-image: none !important;
  background-color: #364F6A !important;
  color: #FFFFFF !important;
  text-shadow: 0 1px 0 #000000;
}

.slider-blue .ui-rangeSlider-label:after {
  color: #364F6A !important;
}

.slider-blue .ui-rangeSlider-arrow {
  color: #2D4259;
}

/* ---------------------------------------------------------------------- */
/*	Nestable List
 /* ---------------------------------------------------------------------- */
.dd {
  position: relative;
  display: block;
  margin: 0;
  padding: 0;
  list-style: none;
  font-size: 13px;
  line-height: 20px;
}

.dd-list {
  display: block;
  position: relative;
  margin: 0;
  padding: 0;
  list-style: none;
}

.dd-list .dd-list {
  padding-left: 30px;
}

.dd-collapsed .dd-list {
  display: none;
}

.dd-item, .dd-empty, .dd-placeholder {
  display: block;
  position: relative;
  margin: 0;
  padding: 0;
  min-height: 20px;
  font-size: 13px;
  line-height: 20px;
}

.dd-handle {
  display: block;
  height: 30px;
  margin: 5px 0;
  padding: 5px 10px;
  color: #333;
  text-decoration: none;
  font-weight: bold;
  border: 1px solid #ccc;
  background: #fafafa;
  background: -webkit-linear-gradient(top, #fafafa 0%, #eee 100%);
  background: -moz-linear-gradient(top, #fafafa 0%, #eee 100%);
  background: linear-gradient(top, #fafafa 0%, #eee 100%);
  -webkit-border-radius: 3px;
  border-radius: 3px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}

.dd-handle:hover {
  color: #2ea8e5;
  background: #fff;
}

.dd-item > button {
  display: block;
  position: relative;
  cursor: pointer;
  float: left;
  width: 25px;
  height: 20px;
  margin: 5px 0;
  padding: 0;
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
  border: 0;
  background: transparent;
  font-size: 12px;
  line-height: 1;
  text-align: center;
  font-weight: bold;
}

.dd-item > button:before {
  content: "+";
  display: block;
  position: absolute;
  width: 100%;
  text-align: center;
  text-indent: 0;
}

.dd-item > button[data-action=collapse]:before {
  content: "-";
}

.dd-placeholder, .dd-empty {
  margin: 5px 0;
  padding: 0;
  min-height: 30px;
  background: #f2fbff;
  border: 1px dashed #b6bcbf;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}

.dd-empty {
  border: 1px dashed #bbb;
  min-height: 100px;
  background-color: #e5e5e5;
  background-image: -webkit-linear-gradient(45deg, #fff 25%, transparent 25%, transparent 75%, #fff 75%, #fff), -webkit-linear-gradient(45deg, #fff 25%, transparent 25%, transparent 75%, #fff 75%, #fff);
  background-image: -moz-linear-gradient(45deg, #fff 25%, transparent 25%, transparent 75%, #fff 75%, #fff), -moz-linear-gradient(45deg, #fff 25%, transparent 25%, transparent 75%, #fff 75%, #fff);
  background-image: linear-gradient(45deg, #fff 25%, transparent 25%, transparent 75%, #fff 75%, #fff), linear-gradient(45deg, #fff 25%, transparent 25%, transparent 75%, #fff 75%, #fff);
  background-size: 60px 60px;
  background-position: 0 0, 30px 30px;
}

.dd-dragel {
  position: absolute;
  pointer-events: none;
  z-index: 9999;
}

.dd-dragel > .dd-item .dd-handle {
  margin-top: 0;
}

.dd-dragel .dd-handle {
  -webkit-box-shadow: 2px 4px 6px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 2px 4px 6px 0 rgba(0, 0, 0, 0.1);
}

/**
 * Nestable Extras
 */
.nestable-lists {
  display: block;
  clear: both;
  padding: 30px 0;
  width: 100%;
  border: 0;
  border-top: 2px solid #ddd;
  border-bottom: 2px solid #ddd;
}

#nestable-menu {
  padding: 0;
  margin: 20px 0;
}

#nestable-output, #nestable2-output {
  width: 100%;
  height: 7em;
  font-size: 13px;
  line-height: 1.333333em;
  padding: 5px;
  margin-bottom: 5px;
}

#nestable2 .dd-handle {
  color: #fff;
  border: 1px solid #999;
  background: #bbb;
  background: -webkit-linear-gradient(top, #bbb 0%, #999 100%);
  background: -moz-linear-gradient(top, #bbb 0%, #999 100%);
  background: linear-gradient(top, #bbb 0%, #999 100%);
}

#nestable2 .dd-handle:hover {
  background: #bbb;
}

#nestable2 .dd-item > button:before {
  color: #fff;
}

.dd-hover > .dd-handle {
  background: #2ea8e5 !important;
}

/**
 * Nestable Draggable Handles
 */
.dd3-content {
  display: block;
  height: 30px;
  margin: 5px 0;
  padding: 5px 10px 5px 40px;
  color: #333;
  text-decoration: none;
  font-weight: bold;
  border: 1px solid #ccc;
  background: #fafafa;
  background: -webkit-linear-gradient(top, #fafafa 0%, #eee 100%);
  background: -moz-linear-gradient(top, #fafafa 0%, #eee 100%);
  background: linear-gradient(top, #fafafa 0%, #eee 100%);
  -webkit-border-radius: 3px;
  border-radius: 3px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}

.dd3-content:hover {
  color: #2ea8e5;
  background: #fff;
}

.dd-dragel > .dd3-item > .dd3-content {
  margin: 0;
}

.dd3-item > button {
  margin-left: 30px;
}

.dd3-handle {
  position: absolute;
  margin: 0;
  left: 0;
  top: 0;
  cursor: pointer;
  width: 30px;
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
  border: 1px solid #aaa;
  background: #ddd;
  background: -webkit-linear-gradient(top, #ddd 0%, #bbb 100%);
  background: -moz-linear-gradient(top, #ddd 0%, #bbb 100%);
  background: linear-gradient(top, #ddd 0%, #bbb 100%);
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.dd3-handle:before {
  content: "\f0c9";
  display: block;
  font-family: FontAwesome;
  position: absolute;
  left: 0;
  top: 3px;
  width: 100%;
  text-align: center;
  text-indent: 0;
  color: #fff;
  font-size: 10px;
  font-weight: normal;
}

.dd3-handle:hover {
  background: #ddd;
}

/* ---------------------------------------------------------------------- */
/*	Icons
 /* ---------------------------------------------------------------------- */
.icons > section {
  margin-top: 20px;
}

.the-icons li {
  line-height: 40px;
}

.the-icons li i {
  transition: font-size 0.2s ease 0s;
}

.the-icons li:hover i {
  font-size: 26px;
}

/* ---------------------------------------------------------------------- */
/*	Backdrop
 /* ---------------------------------------------------------------------- */
.full-white-backdrop {
  background-color: #FFFFFF;
  display: none;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9999;
}

/* ---------------------------------------------------------------------- */
/*	Table and table elements
 /* ---------------------------------------------------------------------- */
th.center, td.center {
  text-align: center;
}

td.center .dropdown-menu {
  text-align: left;
}

.table thead tr {
  color: #707070;
  font-weight: normal;
}

.table.table-bordered thead tr th {
  vertical-align: middle;
}

.table thead > tr > th, .table tbody > tr > th, .table tfoot > tr > th, .table thead > tr > td, .table tbody > tr > td, .table tfoot > tr > td {
  vertical-align: middle;
}

.editable-element {
  border-bottom: 1px dashed #0088CC;
  text-decoration: none;
  opacity: 0.5;
  display: inline;
}

/* ---------------------------------------------------------------------- */
/*	Timepicker
 /* ---------------------------------------------------------------------- */
.bootstrap-timepicker-widget table td input {
  width: 32px !important;
}

.bootstrap-timepicker .add-on i {
  height: auto !important;
  width: auto !important;
}

/* ---------------------------------------------------------------------- */
/*	FileUpload
 /* ---------------------------------------------------------------------- */
.fileupload .uneditable-input {
  white-space: nowrap;
}

.fileupload-new .input-group .btn-file {
  -webkit-border-radius: 0 3px 3px 0 !important;
  -moz-border-radius: 0 3px 3px 0 !important;
  border-radius: 0 3px 3px 0 !important;
}

/* ---------------------------------------------------------------------- */
/*	WYSIWYG
 /* ---------------------------------------------------------------------- */
.wysiwyg {
  padding: 5px 0;
}

.wysiwyg .btn-group:after {
  border-left: 1px solid #E1E6EA;
  bottom: 6px;
  content: "";
  display: block;
  left: -2px;
  max-width: 0;
  position: absolute;
  top: 6px;
  width: 0;
}

.wysiwyg .btn-group .btn, .wysiwyg .btn-group > .inline > .btn {
  background: #FFFFFF !important;
  border: none !important;
  color: #ADB3BE !important;
  margin: 0 !important;
  text-shadow: none !important;
}

.wysiwyg .btn-group .btn:hover, .wysiwyg .btn-group > .inline > .btn:hover {
  color: #333333 !important;
}

.wysiwyg .btn-group .btn:active, .wysiwyg .btn-group > .inline > .btn:active {
  background: #FFFFFF !important;
  border: none !important;
  color: #fff !important;
  margin: 0 !important;
  text-shadow: none !important;
}

/* ---------------------------------------------------------------------- */
/*	Form Wizard
 /* ---------------------------------------------------------------------- */
.swMain > ul {
  display: table;
  list-style: none;
  margin: 0 0 20px 0;
  padding: 0;
  position: relative;
  width: 100%;
}

.swMain > ul li {
  display: table-cell;
  text-align: center;
  width: 1%;
}

.swMain > ul li > a:before {
  border-top: 4px solid #CED1D6;
  content: "";
  display: block;
  font-size: 0;
  height: 1px;
  overflow: hidden;
  position: relative;
  top: 21px;
  width: 100%;
  z-index: 1;
}

.swMain > ul li:first-child > a:before {
  left: 50%;
  max-width: 51%;
}

.swMain > ul li:last-child > a:before {
  max-width: 50%;
  width: 50%;
}

.swMain > ul li > a.selected:before, .swMain li > a.done:before {
  border-color: #5293C4;
}

.swMain > ul .stepNumber {
  background-color: #FFFFFF;
  border: 5px solid #CED1D6;
  border-radius: 100% 100% 100% 100%;
  color: #546474;
  display: inline-block;
  font-size: 15px;
  height: 40px;
  line-height: 30px;
  position: relative;
  text-align: center;
  width: 40px;
  z-index: 2;
}

.swMain > ul li > a.selected .stepNumber {
  border-color: #5293C4;
}

.swMain ul li > a.done .stepNumber {
  border-color: #5293C4;
  background-color: #5293C4;
  color: #fff;
  text-indent: -9999px;
}

.swMain ul li > a.done .stepNumber:before {
  content: "\f00c";
  display: inline;
  float: right;
  font-family: FontAwesome;
  font-weight: 300;
  height: auto;
  text-shadow: none;
  margin-right: 7px;
  text-indent: 0;
}

.swMain > ul li .stepDesc {
  color: #949EA7;
  display: block;
  font-size: 14px;
  margin-top: 4px;
  max-width: 100%;
  table-layout: fixed;
  text-align: center;
  word-wrap: break-word;
  z-index: 104;
}

.swMain > ul li > a.selected .stepDesc, .swMain li > a.done .stepDesc {
  color: #2B3D53;
}

.swMain > ul li > a:hover {
  text-decoration: none;
}

.swMain > ul li > a.disabled {
  cursor: default;
}

.swMain .progress {
  margin-bottom: 30px;
}

.swMain .stepContainer {
  height: auto !important;
}

.swMain .loader {
  display: none;
}

.swMain [class^=button], .swMain [class*=" button"] {
  display: none;
}

.swMain .close {
  display: none;
}

/* ---------------------------------------------------------------------- */
/*	Login
 /* ---------------------------------------------------------------------- */
body.login {
  color: #7F7F7F;
}

body.login.example1 {
  background-color: rgba(0, 0, 0, 0);
}

body.login.example2 {
  background-color: rgba(255, 255, 255, 0);
}

body.login .main-login {
  margin-top: 60px;
}

body.login .logo {
  padding: 20px;
  text-align: center;
}

body.login .box-login, body.login .box-forgot, body.login .box-register {
  background: #FFFFFF;
  border-radius: 5px;
  box-shadow: -30px 30px 50px rgba(0, 0, 0, 0.32);
  overflow: hidden;
  padding: 15px;
}

body.login .box-forgot, body.login .box-register {
  display: none;
}

body.login .form fieldset {
  border: none;
  margin: 0;
  padding: 10px 0 0;
}

body.login a.forgot {
  color: #909090;
  font-size: 12px;
  position: absolute;
  right: 10px;
  text-shadow: 1px 1px 1px #FFFFFF;
  top: 9px;
}

body.login input.password {
  padding-right: 130px;
}

body.login label {
  color: #7F7F7F;
  font-size: 14px;
  margin-top: 5px;
}

body.login .copyright {
  font-size: 11px;
  margin: 0 auto;
  padding: 10px 10px 0;
  text-align: center;
}

body.login .form-actions:before, body.login .form-actions:after {
  content: "";
  display: table;
  line-height: 0;
}

body.login .form-actions:after {
  clear: both;
}

body.login .form-actions {
  margin-top: 15px;
  padding-top: 10px;
  display: block;
}

body.login .new-account {
  border-top: 1px dotted #EEEEEE;
  margin-top: 15px;
  padding-top: 10px;
  display: block;
}

/* ---------------------------------------------------------------------- */
/*	404 and 500 errors
 /* ---------------------------------------------------------------------- */
body.error-full-page {
  background: #ffffff !important;
}

body.error-full-page .page-error {
  margin-top: 60px;
}

.page-error {
  text-align: center;
}

.page-error .error-number {
  display: block;
  font-size: 158px;
  font-weight: 300;
  letter-spacing: -10px;
  line-height: 128px;
  margin-top: 0;
  text-align: center;
}

.page-error .error-details {
  display: block;
  padding-top: 0;
  text-align: center;
}

.page-error .error-details .btn-return {
  margin: 10px 0;
}

.page-error .error-details h3 {
  margin-top: 0;
}

body.error-full-page {
  overflow: hidden;
}

.error-full-page img {
  display: none;
}

.error-full-page #canvas {
  position: absolute;
  top: 0px;
  left: 0px;
}

.error-full-page #sound {
  position: absolute;
  width: 30%;
  height: 30%;
  overflow-y: auto;
  margin-left: 35%;
  -moz-border-radius: 15px;
  border-radius: 15px;
  opacity: 0.3;
  margin-top: 5%;
}

.error-full-page .video {
  position: absolute;
  width: 90%;
  height: 80%;
  margin-left: 5%;
  margin-top: 5%;
}

/* ---------------------------------------------------------------------- */
/*	Inline Editor
 /* ---------------------------------------------------------------------- */
#inline-container [contenteditable=true] {
  padding: 10px;
}

#inline-container {
  border: 1px solid #CCCCCC;
  margin: 30px auto 0;
  padding: 10px;
}

#inline-container hr {
  background-color: #05B2D2;
  height: 5px;
  color: #05B2D2;
  border: 0;
}

#inline-header {
  overflow: hidden;
  padding: 0 0 30px;
  position: relative;
}

#inline-headerLeft {
  padding-top: 10px;
}

#inline-headerLeft h2, #inline-headerLeft h3 {
  font-weight: normal;
  margin: 0;
  overflow: hidden;
  text-align: center;
}

#inline-headerLeft h2 {
  font-family: "Arial Black", arial-black;
  font-size: 52px;
  line-height: 1.1em;
  text-transform: uppercase;
}

#inline-headerLeft h3 {
  color: #666666;
  font-size: 24px;
  line-height: 1.1em;
  margin: 0.2em 0 0;
}

#inline-headerRight p {
  margin: 0;
  text-align: justify;
}

#inline-headerRight p + p {
  margin-top: 20px;
}

#inline-headerRight div {
  color: #666666;
  font-size: 16px;
}

#inline-columns {
  color: #333333;
  overflow: hidden;
  padding-top: 20px;
}

#inline-columns blockquote {
  font-family: "Times New Roman", Times, Georgia, serif;
  font-size: 2em;
  font-style: italic;
  margin: 30px 40px;
  position: relative;
  border: none;
}

#inline-columns blockquote:before {
  color: #666666;
  content: "“";
  font-size: 60px;
  left: -40px;
  line-height: 60px;
  position: absolute;
  text-align: center;
  width: 35px;
  clear: both;
  display: block;
  font-family: "Times New Roman", Times, Georgia, serif;
  font-weight: bold;
  position: absolute;
  text-align: center;
}

#inline-tagLine {
  border-top: 5px solid #05B2D2;
  padding-top: 20px;
}

#inline-taglist {
  display: inline-block;
  font-weight: bold;
  margin: 0 0 0 20px;
}

/* ---------------------------------------------------------------------- */
/*	Crop Image
 /* ---------------------------------------------------------------------- */
.crop-image form {
  margin: 1.5em 0;
}

.jcrop-holder #preview-pane {
  display: block;
  position: absolute;
  z-index: 1000;
  top: 10px;
  right: -280px;
  padding: 6px;
  border: 1px rgba(0, 0, 0, 0.4) solid;
  background-color: white;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  -webkit-box-shadow: 1px 1px 5px 2px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 1px 1px 5px 2px rgba(0, 0, 0, 0.2);
  box-shadow: 1px 1px 5px 2px rgba(0, 0, 0, 0.2);
}

/* The Javascript code will set the aspect ratio of the crop
 area based on the size of the thumbnail preview,
 specified here */
#preview-pane .preview-container {
  width: 250px;
  height: 170px;
  overflow: hidden;
}

/* ---------------------------------------------------------------------- */
/*	Timeline
 /* ---------------------------------------------------------------------- */
div.timeline {
  font-family: Arial;
  margin: 0 auto;
  overflow: hidden;
  position: relative;
}

div.timeline .columns > li:nth-child(2n+1) {
  float: left;
  width: 50%;
}

div.timeline .columns > li:nth-child(2n+2) {
  float: right;
  margin-top: 20px;
  width: 50%;
}

div.timeline div.date_separator {
  clear: both;
  height: 60px;
  position: relative;
  text-align: center;
}

div.timeline div.date_separator span {
  border-radius: 5px 5px 5px 5px;
  display: block;
  font-size: 14px;
  height: 30px;
  line-height: 30px;
  margin-top: -16px;
  position: absolute;
  top: -200%;
  transition: all 0.3s linear 0s;
  width: 200px;
  top: 50%;
  left: 50%;
  margin-left: -100px;
  border: 1px solid #BBBBBB;
  background-color: #FFFFFF;
  color: #666666;
}

div.timeline div.spine {
  border-radius: 2px 2px 2px 2px;
  position: absolute;
  top: 0;
  transition: bottom 0.5s linear 0s;
  width: 4px;
  left: 50%;
  margin-left: -2px;
  bottom: 0;
  background-color: #CCCCCC;
}

div.timeline_element {
  border-radius: 5px 5px 5px 5px;
  clear: both;
  margin: 10px 0;
  opacity: 0;
  position: relative;
  transition: all 0.2s linear 0s;
  background-color: #FFFFFF;
  border: 1px solid #CCCCCC;
  color: #000000;
  text-shadow: none;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1);
}

div.timeline_element:hover {
  border: 1px solid #AAAAAA;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.2);
}

div.timeline .columns {
  margin: 0;
  padding: 0;
  list-style: none;
}

div.timeline .columns > li:nth-child(2n+1) div.timeline_element {
  float: right;
  left: 10%;
  margin-right: 30px;
  left: 0;
  opacity: 1;
}

div.timeline .columns > li:nth-child(2n+2) div.timeline_element {
  float: left;
  margin-left: 30px;
  right: 10%;
  opacity: 1;
  right: 0;
}

div.timeline div.column_center div.timeline_element {
  margin: 20px auto;
  opacity: 1;
}

div.timeline_element:before {
  content: "";
  display: block;
  height: 0;
  position: absolute;
  width: 26px;
  border-top: 1px dashed #CCCCCC;
}

div.timeline .columns > li:nth-child(2n+1) div.timeline_element:before {
  right: -27px;
  top: 15px;
}

div.timeline .columns > li:nth-child(2n+2) div.timeline_element:before {
  left: -27px;
  top: 15px;
}

div.timeline div.column_center div.timeline_element:before {
  display: none;
}

div.timeline_element:after {
  border-radius: 100% 100% 100% 100%;
  content: "";
  display: block;
  height: 10px;
  position: absolute;
  width: 10px;
  background-color: #BBBBBB;
  border: 1px solid #FFFFFF;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.2);
}

div.timeline_element:hover:after {
  z-index: 100;
  background-color: #FFFFFF;
  border: 1px solid #CCCCCC;
  z-index: 100;
}

div.timeline .columns > li:nth-child(2n+1) div.timeline_element:after {
  right: -36px;
  top: 10px;
}

div.timeline .columns > li:nth-child(2n+2) div.timeline_element:after {
  left: -36px;
  top: 10px;
}

div.timeline.center div.column_center div.timeline_element:after {
  display: none;
}

/* ie8 fixes */
.ie8 div.timeline_element:after {
  display: none;
}

.ie8 div.timeline_element:before {
  display: none;
}

/**/
div.timeline_element div.timeline_title {
  font-size: 10px;
  margin: 10px;
  overflow: hidden;
  position: relative;
  text-transform: uppercase;
  color: #AAAAAA;
}

div.timeline_element div.title span.timeline_label {
  display: block;
  left: 0;
  overflow: hidden;
  position: absolute;
  right: 100px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

div.timeline_element div.timeline_title span.timeline_date {
  float: right;
}

div.timeline_element div.content {
  font-size: 13px;
  padding: 0 10px 10px;
}

div.timeline_element div.readmore {
  font-size: 12px;
  padding: 10px;
  text-align: right;
}

div.timeline div.description_container {
  height: 80px;
  margin: 5px 5px 5px 90px;
  overflow: hidden;
}

div.timeline_element.teal {
  background-color: #569099;
  border-color: #4D8189;
  color: #FFFFFF;
}

div.timeline_element.teal:after {
  background-color: #569099;
}

div.timeline_element.teal:hover:after {
  background-color: #FFFFFF;
  border: 1px solid #569099;
  z-index: 100;
}

div.timeline_element.teal div.timeline_title {
  color: #EEEEEE;
}

div.timeline_element.green {
  background-color: #3D9400;
  border-color: #327B00;
  color: #FFFFFF;
}

div.timeline_element.green:after {
  background-color: #3D9400;
}

div.timeline_element.green:hover:after {
  background-color: #FFFFFF;
  border: 1px solid #3D9400;
  z-index: 100;
}

div.timeline_element.green div.timeline_title {
  color: #EEEEEE;
}

div.timeline_element.bricky {
  background-color: #C83A2A;
  border-color: #B33426;
  color: #FFFFFF;
}

div.timeline_element.bricky:after {
  background-color: #C83A2A;
}

div.timeline_element.bricky:hover:after {
  background-color: #FFFFFF;
  border: 1px solid #C83A2A;
  z-index: 100;
}

div.timeline_element.bricky div.timeline_title {
  color: #EEEEEE;
}

div.timeline_element.purple {
  background-color: #57517B;
  border-color: #413D5C;
  color: #FFFFFF;
}

div.timeline_element.purple:after {
  background-color: #57517B;
}

div.timeline_element.purple:hover:after {
  background-color: #FFFFFF;
  border: 1px solid #57517B;
  z-index: 100;
}

div.timeline_element.purple div.timeline_title {
  color: #EEEEEE;
}

/* ---------------------------------------------------------------------- */
/*	Messages
 /* ---------------------------------------------------------------------- */
.panel-body.messages {
  padding: 0;
}

.messages-list {
  background-color: #F8F7FA;
  border-right: 1px solid #ECEAF3;
  float: left;
  height: 620px;
  list-style: none outside none;
  margin: -4px 0 0;
  padding: 0;
  width: 260px;
}

.messages-list .messages-item {
  border-bottom: 1px solid #ECEAF3;
  padding: 5px 15px 5px 25px;
  position: relative;
}

.messages-list .messages-item span {
  color: #555555;
  display: block;
}

.messages-list .messages-item .messages-item-star, .messages-list .messages-item .messages-item-attachment, .messages-list .messages-item .messages-item-time, .messages-list .messages-item .messages-item-time .messages-item-actions {
  position: absolute;
}

.messages-list .messages-item .messages-item-star {
  left: 7px;
  opacity: 0;
  transition: opacity 0.2s ease-in-out 0s;
}

/* ie8 fixes */
.ie8 .messages-list .messages-item .messages-item-star {
  display: none;
}

.ie8 .messages-list .messages-item:hover .messages-item-star {
  display: block;
}

/**/
.messages-list .messages-item .messages-item-attachment {
  left: 7px;
  top: 25px;
}

.messages-list .messages-item .messages-item-avatar {
  border-radius: 4px 4px 4px 4px;
  float: left;
  height: 40px;
  width: 40px;
}

.messages-list .messages-item .messages-item-from, .messages-list .messages-item .messages-item-subject {
  margin-left: 45px;
}

.messages-list .messages-item .messages-item-from {
  font-weight: bold;
  margin-top: 2px;
}

.messages-list .messages-item .messages-item-time {
  opacity: 1;
  right: 15px;
  top: 8px;
  transition: opacity 0.2s ease-in-out 0s;
}

.messages-list .messages-item .messages-item-time .text {
  color: #999999;
  font-size: 12px;
}

.messages-list .messages-item .messages-item-time .messages-item-actions {
  opacity: 0;
  right: 0;
  top: 0;
  transition: opacity 0.2s ease-in-out 0s;
  width: 75px;
}

/* ie8 fixes */
.ie8 .messages-list .messages-item .messages-item-time .messages-item-actions {
  display: none;
}

/**/
.messages-list .messages-item .messages-item-time .messages-item-actions > a, .messages-list .messages-item .messages-item-time .messages-item-actions .dropdown > a {
  color: #555555;
  margin-left: 5px;
}

.messages-list .messages-item .messages-item-time .messages-item-actions > div {
  display: inline-block;
}

.messages-list .messages-item .messages-item-time .messages-item-actions .dropdown-menu {
  margin-top: 0;
}

.messages-list .messages-item .messages-item-time .messages-item-actions .tag-icon {
  border-radius: 3px 3px 3px 3px;
  display: inline-block;
  height: 11px;
  margin: 0 5px 0 -13px;
  width: 11px;
}

.messages-list .messages-item .messages-item-time:hover .text {
  opacity: 0;
}

.messages-list .messages-item .messages-item-time:hover .messages-item-actions {
  opacity: 1;
}

.messages-list .messages-item .messages-item-subject {
  font-size: 12px;
  margin-bottom: 4px;
}

.messages-list .messages-item .messages-item-preview {
  color: #999999;
  font-size: 12px;
}

.messages-list .messages-item:hover {
  background-color: #F2F0F7;
  cursor: pointer !important;
}

.messages-list .messages-item:hover .messages-item-star {
  opacity: 0.2;
}

.messages-list .messages-item.active {
  background-color: #ECEAF3;
}

.messages-list .messages-item.starred .messages-item-star {
  display: block;
  opacity: 1;
}

.messages-content {
  background-color: rgba(0, 0, 0, 0);
  border-top: 1px solid #ECEAF3;
  height: auto;
  margin-left: 260px;
  margin-top: 4px;
  position: relative;
  width: auto;
}

.messages-content .message-header {
  background-color: #F8F7FA;
  border-bottom: 1px solid #ECEAF3;
  padding: 10px 15px;
}

.messages-content .message-header .message-from {
  font-weight: bold;
}

.messages-content .message-header .message-to {
  color: #999999;
}

.messages-content .message-header .message-time {
  color: #999999;
  position: absolute;
  right: 15px;
  top: 10px;
}

.messages-content .message-header .message-actions {
  left: 50%;
  margin-left: -65.7px;
  position: absolute;
  width: 115px;
}

.messages-content .message-header .message-actions a {
  background-color: #FAFAFA;
  border-bottom: 1px solid #F5F4F9;
  border-left: 1px solid #F5F4F9;
  border-top: 1px solid #F5F4F9;
  color: #CFCAE0;
  margin: 0 0 0 -4px;
  padding: 4px 7px;
}

.messages-content .message-header .message-actions a:hover {
  color: #5E518D;
}

.messages-content .message-header .message-actions a:first-child {
  border-bottom-left-radius: 15px;
  border-top-left-radius: 15px;
  padding-left: 10px;
}

.messages-content .message-header .message-actions a:last-child {
  border-bottom-right-radius: 15px;
  border-right: 1px solid #F5F4F9;
  border-top-right-radius: 15px;
}

.messages-content .message-content {
  padding: 10px;
}

/* ---------------------------------------------------------------------- */
/*	Pricing Tables
 /* ---------------------------------------------------------------------- */
#pricing_table_example1 .pricing-table {
  background: none repeat scroll 0 0 #FFFFFF;
  border: 1px solid #DDDDDD;
  color: #333333;
  float: left;
  font: 12px "Lucida Sans", "trebuchet MS", Arial, Helvetica;
  padding: 20px;
  position: relative;
  text-shadow: 0 1px rgba(255, 255, 255, 0.8);
  text-align: center;
}

#pricing_table_example1 .featured {
  border-radius: 5px 5px 5px 5px;
  border-width: 1px;
  box-shadow: 20px 0 10px -10px rgba(0, 0, 0, 0.15), -20px 0 10px -10px rgba(0, 0, 0, 0.15);
  z-index: 2;
}

#pricing_table_example1 .pricing-table:nth-child(1) {
  border-radius: 5px 0 0 5px;
}

#pricing_table_example1 .pricing-table:nth-child(4) {
  border-radius: 0 5px 5px 0;
}

#pricing_table_example1 .pricing-table h3 {
  background-color: #EEEEEE;
  background-image: linear-gradient(#FFFFFF, #EEEEEE);
  font-size: 20px;
  font-weight: normal;
  margin: -20px -20px 50px;
  padding: 20px;
}

#pricing_table_example1 .featured h3 {
  background-color: #DDDDDD;
  background-image: linear-gradient(#EEEEEE, #DDDDDD);
  border-radius: 5px 5px 0 0;
}

#pricing_table_example1 .pricing-table:nth-child(1) h3 {
  border-radius: 5px 0 0 0;
}

#pricing_table_example1 .pricing-table:nth-child(4) h3 {
  border-radius: 0 5px 0 0;
}

#pricing_table_example1 .pricing-table h3 span {
  background: none repeat scroll 0 0 #FFFFFF;
  border: 5px solid #FFFFFF;
  border-radius: 100px 100px 100px 100px;
  box-shadow: 0 5px 20px #DDDDDD inset, 0 3px 0 #999999 inset;
  color: #777777;
  display: block;
  font: bold 35px/100px Georgia, Serif;
  height: 100px;
  margin: 10px auto -65px;
  width: 100px;
  line-height: 85px;
}

#pricing_table_example1 .pricing-table ul {
  list-style: none outside none;
  margin: 20px 0 0;
  padding: 0;
}

#pricing_table_example1 .pricing-table li {
  border-top: 1px solid #DDDDDD;
  padding: 10px 0;
}

#pricing_table_example1 .pricing-table .signup {
  background-color: #72CE3F;
  background-image: linear-gradient(#72CE3F, #62BC30);
  border-radius: 3px 3px 3px 3px;
  box-shadow: 0 1px 0 rgba(255, 255, 255, 0.5), 0 2px 0 rgba(0, 0, 0, 0.7);
  color: #FFFFFF;
  display: inline-block;
  font: bold 14px Arial, Helvetica;
  margin: 20px 0 0;
  padding: 8px 20px;
  position: relative;
  text-decoration: none;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.3);
  text-transform: uppercase;
}

#pricing_table_example1 .pricing-table .signup:hover {
  background-color: #62BC30;
  background-image: linear-gradient(#62BC30, #72CE3F);
}

#pricing_table_example1 .pricing-table .signup:active, #pricing_table_example1 .pricing-table .signup:focus {
  background: none repeat scroll 0 0 #62BC30;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.7) inset;
  top: 2px;
}

#pricing_table_example2 .featured {
  z-index: 2;
}

#pricing_table_example2 .top {
  background: none repeat scroll 0 0 #2F2E35;
  border-radius: 10px 10px 0 0;
  height: 50px;
}

#pricing_table_example2 .top h2 {
  color: #FFFFFF;
  font: 300 30px/50px Helvetica, Verdana, sans-serif;
  text-align: center;
  margin: 0;
}

#pricing_table_example2 .pricing-table {
  background: none repeat scroll 0 0 #FFFFFF;
  border-radius: 10px 10px 10px 10px;
  box-shadow: 2px 2px 9px rgba(0, 0, 0, 0.3);
  position: relative;
  text-align: center;
  padding: 0 0 20px 0;
}

#pricing_table_example2 .pricing-table ul {
  font: 300 18px/2 Helvetica, Verdana, sans-serif;
  list-style-type: none;
  margin: 20px 20px 0 20px;
  padding: 0;
}

#pricing_table_example2 .pricing-table ul strong {
  font-weight: bold;
}

#pricing_table_example2 .pricing-table hr {
  background-color: #BCBEC0;
  border: 0 none;
  color: #BCBEC0;
  height: 1px;
  margin: 20px 0 0 0;
}

#pricing_table_example2 .pricing-table h1 {
  font: bold 88px/1 Helvetica, Verdana, sans-serif;
  margin: 20px 0 0;
}

#pricing_table_example2 .pricing-table h1 sup {
  font-size: 45px;
}

#pricing_table_example2 .pricing-table p {
  color: #BCBEC0;
  font: 500 14px/1 Helvetica, Verdana, sans-serif;
}

/* ---------------------------------------------------------------------- */
/*	Coming Soon
 /* ---------------------------------------------------------------------- */
.coming-soon {
  text-align: center;
  background-color: #ffffff !important;
}

.timer-area {
  background-color: rgba(0, 0, 0, 0);
  margin-bottom: 4em;
  padding-top: 2em;
  color: #1F2225;
  font: 1em/1.5em "Helvetica Neue", Arial, Helvetica, Geneva, sans-serif;
}

.timer-area h1 {
  color: #FFFFFF;
}

ul#countdown {
  margin: 0;
  padding: 0;
}

ul#countdown li {
  display: inline-block;
  width: 104px;
  margin-bottom: 4em;
  text-align: center;
}

ul#countdown li span {
  font-size: 3em;
  font-weight: bold;
  color: #1f2225;
  height: 108px;
  line-height: 108px;
  position: relative;
}

ul#countdown li span:before {
  content: "";
  width: 100%;
  height: 1px;
  border-top: 1px solid white;
  position: absolute;
  top: 25px;
}

ul#countdown li p.timeRefDays, ul#countdown li p.timeRefHours, ul#countdown li p.timeRefMinutes, ul#countdown li p.timeRefSeconds {
  margin-top: 1em;
  color: #909091;
  text-transform: uppercase;
  font-size: 0.875em;
}

.coming-soon #disclaimer {
  display: block;
}

.coming-soon #disclaimer p {
  color: #909091;
  font-size: 0.875em;
  font-style: italic;
}

.coming-soon #disclaimer p span {
  color: #2A9CCC;
  font-size: 1.2em;
}

/* ---------------------------------------------------------------------- */
/*	Flot Chart
 /* ---------------------------------------------------------------------- */
.flot-container {
  box-sizing: border-box;
  position: relative;
  height: 450px;
}

.flot-placeholder {
  height: 100%;
  font-size: 14px;
  line-height: 1.2em;
}

.legend table {
  border-spacing: 5px;
}

.flot-container #choices {
  position: absolute;
  width: 135px;
  right: 0;
  top: 0;
}

#placeholder2 {
  margin-right: 135px;
}

.flot-small-container {
  height: 300px;
}

.flot-mini-container {
  height: 135px;
}

.flot-medium-container {
  height: 360px;
}

/* ---------------------------------------------------------------------- */
/*	Maps
 /* ---------------------------------------------------------------------- */
.map {
  display: block;
  height: 350px;
  margin: 0 auto;
}

.map.large {
  height: 500px;
}

/* ---------------------------------------------------------------------- */
/*	Overlay
 /* ---------------------------------------------------------------------- */
.overlay {
  display: block;
  text-align: center;
  color: #fff;
  font-size: 60px;
  line-height: 80px;
  opacity: 0.8;
  background: #4477aa;
  border: solid 3px #336699;
  border-radius: 4px;
  box-shadow: 2px 2px 10px #333;
  text-shadow: 1px 1px 1px #666;
  padding: 0 4px;
}

.overlay_arrow {
  left: 50%;
  margin-left: -16px;
  width: 0;
  height: 0;
  position: absolute;
}

.overlay_arrow.above {
  bottom: -15px;
  border-left: 16px solid transparent;
  border-right: 16px solid transparent;
  border-top: 16px solid #336699;
}

.overlay_arrow.below {
  top: -15px;
  border-left: 16px solid transparent;
  border-right: 16px solid transparent;
  border-bottom: 16px solid #336699;
}

/* ---------------------------------------------------------------------- */
/*	Calendar
 /* ---------------------------------------------------------------------- */
#calendar {
  position: relative;
}

.fc-event {
  padding: 2px;
}

.fc-day-number {
  margin: 2px 2px 0 0;
}

.fc-header-title h2 {
  color: #666666;
  font-family: "Raleway", sans-serif;
  font-weight: 200;
  letter-spacing: -1px;
}

.fc-today .fc-day-number {
  font-weight: bold;
}

.fc-state-highlight {
  background: #F5F5F5 !important;
}

.fc-button {
  color: #666666 !important;
}

.fc-state-default {
  background-color: #ffffff !important;
  background-image: none !important;
  border-color: #666666 !important;
  box-shadow: none !important;
  color: #666666;
  text-shadow: none !important;
}

.fc-state-down, .fc-state-active {
  box-shadow: none !important;
  outline: 0 none;
  background-color: #569099 !important;
  border-color: #4D8189 !important;
  color: #FFFFFF !important;
}

.fc-header-left {
  position: absolute !important;
  top: 0;
  left: 0;
  width: 50% !important;
}

.fc-header-right {
  position: absolute !important;
  top: 0;
  right: 0;
  width: 50% !important;
}

.fc-header-center {
  display: block !important;
  padding-top: 40px !important;
}

.popover {
  width: 500px !important;
  max-width: 500px !important;
}

.event-category {
  color: #ffffff;
  padding: 5px;
  border-radius: 3px;
  margin: 5px 0;
}

.label-default, .badge-default {
  background-color: #3A87AD !important;
  border-color: #3A87AD !important;
}

.label-orange, .badge-orange {
  background-color: #E6674A !important;
  border-color: #E6674A !important;
}

.label-purple, .badge-purple {
  background-color: #57517B !important;
  border-color: #57517B !important;
}

.label-yellow, .badge-yellow {
  background-color: #FFB848 !important;
  border-color: #FFB848 !important;
}

.label-teal, .badge-teal {
  background-color: #569099 !important;
  border-color: #569099 !important;
}

.label-beige, .badge-beige {
  background-color: #CCCC99 !important;
  border-color: #CCCC99 !important;
}

.label-green, .badge-green {
  background-color: #3D9400 !important;
  border-color: #3D9400 !important;
}

/* ---------------------------------------------------------------------- */
/*	Lock Screen
 /* ---------------------------------------------------------------------- */
body.lock-screen {
  color: #7F7F7F;
  background-color: rgba(0, 0, 0, 0);
}

.main-ls {
  height: 250px;
  left: 50%;
  margin-left: -240px;
  margin-top: -125px;
  position: absolute;
  top: 50%;
  width: 480px;
}

body.lock-screen .box-ls {
  background: #FFFFFF;
  box-shadow: -30px 30px 50px rgba(0, 0, 0, 0.32);
  overflow: hidden;
  padding: 15px;
}

body.lock-screen .logo {
  padding: 10px 0;
}

body.lock-screen .copyright {
  font-size: 11px;
  margin: 0 auto;
  padding: 10px 0;
}

body.lock-screen .user-info {
  float: right;
  width: 285px;
}

body.lock-screen .user-info h1 {
  color: #666666;
  font-family: "Raleway", sans-serif;
  font-size: 30px;
  font-weight: 300;
  line-height: 32px;
  margin-top: 0;
}

body.lock-screen .user-info h1 i {
  color: #cccccc;
}

body.lock-screen .user-info > span {
  color: #666666;
  display: block;
  font-size: 12px;
  margin-bottom: 5px;
}

body.lock-screen .user-info > span em {
  color: #333333;
}

/* ---------------------------------------------------------------------- */
/*	daterangepicker
 /* ---------------------------------------------------------------------- */
.daterangepicker {
  margin-top: 51px !important;
}

/* ---------------------------------------------------------------------- */
/*	Style Selector
 /* ---------------------------------------------------------------------- */
#style_selector {
  background: none repeat scroll 0 0 #FFFFFF;
  position: fixed;
  right: 0;
  top: 100px;
  width: 215px;
  z-index: 999;
}

#style_selector_container {
  border: 1px solid rgba(23, 24, 26, 0.15);
  border-bottom-left-radius: 2px;
  border-top-left-radius: 2px;
  box-shadow: 0 2px 9px 2px rgba(0, 0, 0, 0.14);
  display: none;
}

#style_selector_container img {
  border-radius: 100% 100% 100% 100%;
}

.style-main-title {
  background: #D9D9D9;
  border-bottom: 1px solid rgba(23, 24, 26, 0.15);
  color: #000000;
  font-size: 15px;
  height: 44px;
  line-height: 44px;
  text-align: center;
}

.box-title {
  font-size: 12px;
  height: 33px;
  line-height: 33px;
  text-align: center;
  color: #AAA9A9 !important;
}

.input-box {
  border-bottom: 1px solid rgba(23, 24, 26, 0.15);
  padding: 0 10px 10px 40px;
}

.input-box input[type=text] {
  background: none repeat scroll 0 0 #F7F7F7;
  border: 1px solid rgba(23, 24, 26, 0.15);
  color: #000000;
  font-size: 11px;
  margin-left: 10px;
  padding: 3px;
  width: 60px;
}

.input-box select {
  background: none repeat scroll 0 0 #F7F7F7;
  border: 1px solid rgba(23, 24, 26, 0.15);
  color: #000000;
  margin-left: 5px;
  width: 120px;
}

#style_selector .style-toggle {
  background: #ffffff;
  cursor: pointer;
  height: 44px;
  width: 35px;
  border: 1px solid rgba(23, 24, 26, 0.15);
  border-right: none;
  border-radius: 5px 0 0 5px;
  line-height: 44px;
  text-align: center;
  color: #007AFF;
  font-size: 14px;
}

#style_selector > .open:before {
  content: "\e162";
  font-family: "clip-font";
  line-height: 1;
}

#style_selector > .open {
  background-position: left top;
  left: -35px;
  position: absolute;
  top: 44px;
  width: 35px;
  opacity: 1;
}

#style_selector > .close:before {
  content: "\e163";
  font-family: "clip-font";
  line-height: 1;
}

#style_selector > .close {
  background-position: left bottom;
  position: absolute;
  right: 0;
  top: 45px;
  width: 35px;
  opacity: 0.9;
}

#style_selector .images {
  border-bottom: 1px solid rgba(23, 24, 26, 0.15);
  padding-bottom: 10px;
  padding-left: 25px;
  position: relative;
}

#style_selector .images img {
  height: 24px;
  margin-bottom: 7px;
  margin-right: 7px;
  width: 25px;
  z-index: 1000;
}

#style_selector .images img.active {
  border: 0 solid #CCCCCC;
  opacity: 0.5;
}

#style_selector .images .form-group {
  width: 50px;
  text-align: center;
  color: #AAA9A9;
  font-size: 10px;
  margin-bottom: 0;
}

.layout-boxed > .navbar, .layout-boxed .main-container, .layout-boxed > .footer {
  max-width: 1170px !important;
  margin-left: auto !important;
  margin-right: auto !important;
  border-left-width: 1px;
  border-left-style: solid;
  border-right-width: 1px;
  border-right-style: solid;
}

#style_selector .images input {
  display: none;
}

#style_selector .images .form-group {
  display: inline-block;
}

#style_selector .images .add-on {
  background-color: #EEEEEE;
  border: 1px solid #CCCCCC;
  display: inline-block;
  font-weight: normal;
  line-height: 20px;
  min-width: 20px;
  padding: 5px;
  text-align: center;
  text-shadow: 0 1px 0 #FFFFFF;
  width: auto;
  margin-left: 2px;
}

#style_selector .images .add-on i {
  cursor: pointer;
  display: block;
  height: 20px;
  width: 20px;
}

#style_selector .clear_style {
  margin-right: 20px;
}

/* ---------------------------------------------------------------------- */
/*	Home elements
 /* ---------------------------------------------------------------------- */
.circle-icon {
  background-color: #333333;
  border-radius: 75px 75px 75px 75px;
  color: #FFFFFF;
  margin-top: 2px;
  text-align: center;
  height: 55px;
  line-height: 56px;
  width: 55px;
  display: inline-block;
  font-size: 24px !important;
}

.circle-icon.circle-green {
  background-color: #3D9400;
  border-color: #327B00;
  color: #FFFFFF;
}

.circle-icon.circle-teal {
  background-color: #569099;
  border-color: #4D8189;
  color: #FFFFFF;
}

.circle-icon.circle-bricky {
  background-color: #C83A2A;
  border-color: #b33426;
  color: #ffffff;
}

.core-box {
  margin-bottom: 20px;
  display: inline-block;
  padding-bottom: 10px;
  border-bottom: 1px solid #EEEEEE;
}

.core-box .heading {
  margin-bottom: 15px;
}

.core-box .heading .circle-icon {
  float: left;
}

.core-box .heading h2 {
  line-height: 55px !important;
  margin: 0;
  padding-left: 65px;
  font-weight: normal;
  color: #666666;
  font-size: 18px;
}

.core-box .content {
  color: #747474 !important;
  line-height: 20px;
  margin-bottom: 15px;
}

.core-box .view-more {
  float: right;
}

.core-box .view-more:hover {
  text-decoration: none;
}

.discussion {
  list-style: none;
  margin: 0;
  padding: 0;
}

.discussion li {
  overflow: hidden;
  padding: 5px 5px 10px;
}

.discussion .avatar {
  position: relative;
  width: 50px;
  height: 50px;
  display: inline;
  overflow: hidden;
}

.other .messages:after {
  -moz-border-bottom-colors: none;
  -moz-border-left-colors: none;
  -moz-border-right-colors: none;
  -moz-border-top-colors: none;
  border-color: #E5E5EA #E5E5EA rgba(0, 0, 0, 0) rgba(0, 0, 0, 0);
  border-image: none;
  border-style: solid;
  border-width: 5px;
  content: "";
  height: 0;
  position: absolute;
  left: -9px;
  top: 0;
  width: 0;
}

.other .messages {
  margin-left: 62px;
  background: #E5E5EA;
}

.other .avatar {
  float: left;
}

.self .messages {
  border-bottom-right-radius: 0;
  margin-right: 62px;
  background: #33CA22;
  color: #ffffff;
}

.self .avatar {
  float: right;
}

.self .messages:after {
  -moz-border-bottom-colors: none;
  -moz-border-left-colors: none;
  -moz-border-right-colors: none;
  -moz-border-top-colors: none;
  border-color: #33CA22 rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) #33CA22;
  border-image: none;
  border-style: solid;
  border-width: 5px;
  top: 0;
  content: "";
  height: 0;
  right: -9px;
  position: absolute;
  width: 0;
}

.messages {
  border-radius: 2px 2px 2px 2px;
  padding: 10px;
  position: relative;
  margin-top: 10px;
}

.messages p {
  font-size: 14px;
  margin: 0 0 0.2em;
}

.messages time {
  color: #333333;
  font-size: 11px;
}

.self .messages time {
  color: #ffffff;
}

.chat-form {
  margin-bottom: 20px;
}

/* ---------------------------------------------------------------------- */
/*	Sparklines
 /* ---------------------------------------------------------------------- */
.mini-stats {
  border-left: 1px solid #DDDDDD;
  list-style: none;
  margin: 0;
  padding: 0;
}

.mini-stats li {
  border-left: 1px solid #FFFFFF;
  border-right: 1px solid #DDDDDD;
  padding-bottom: 6px;
  text-align: center;
}

.mini-stats .sparkline_bar_good, .mini-stats .sparkline_bar_neutral, .mini-stats .sparkline_bar_bad {
  font-size: 12px;
  font-weight: bold;
  text-align: center;
}

.mini-stats li:last-child {
  border-right: 0 none;
}

.mini-stats .values {
  font-size: 12px;
  padding: 10px 0;
}

.mini-stats .values strong {
  display: block;
  font-size: 18px;
  margin-bottom: 2px;
}

.mini-stats .sparkline_bar_good, .mini-stats .sparkline_bar_neutral, .mini-stats .sparkline_bar_bad {
  font-size: 12px;
  font-weight: bold;
  text-align: center;
}

.mini-stats .sparkline_bar_good {
  color: #459D30;
}

.mini-stats .sparkline_bar_neutral {
  color: #757575;
}

.mini-stats .sparkline_bar_bad {
  color: #BA1E20;
}

.jqstooltip {
  width: auto !important;
  height: auto !important;
  padding: 2px 6px !important;
  background-color: rgba(0, 0, 0, 0.7) !important;
  border: 0 !important;
  border-radius: 3px;
}

/* ---------------------------------------------------------------------- */
/*	Easy Pie Chart
 /* ---------------------------------------------------------------------- */
.easy-pie-chart {
  position: relative;
  text-align: center;
}

.easy-pie-chart .number {
  position: relative;
  display: inline-block;
  width: 70px;
  height: 70px;
  text-align: center;
}

.easy-pie-chart canvas {
  position: absolute;
  top: 0;
  left: 0;
}

.percent {
  display: inline-block;
  line-height: 70px;
  z-index: 2;
}

.percent:after {
  content: "%";
  margin-left: 0.1em;
  font-size: 0.8em;
}

.label-chart {
  color: #333333;
  font-size: 16px;
  font-weight: 300;
  display: inline;
  line-height: 1;
  padding: 0.2em 0.6em 0.3em;
  text-align: center;
  vertical-align: baseline;
  white-space: nowrap;
}

/* ---------------------------------------------------------------------- */
/*	User profile
 /* ---------------------------------------------------------------------- */
.user-left {
  border-right: 1px solid #DDDDDD;
  padding-right: 15px;
}

.user-left a:hover, .user-left a:focus {
  text-decoration: none;
}

.user-left td .edit-user-info {
  visibility: hidden;
}

.user-left tr:hover .edit-user-info {
  visibility: visible;
}

.user-left .user-image {
  position: relative;
  display: inline-block;
}

.user-image .user-image-buttons {
  position: absolute;
  top: 5px;
  right: 5px;
  display: none;
}

.user-image:hover .user-image-buttons {
  display: block;
}

.user-edit-image-buttons {
  display: inline;
}

#projects .progress {
  margin-bottom: 0;
}

/* ---------------------------------------------------------------------- */
/*	Gallery
 /* ---------------------------------------------------------------------- */
.gallery-img {
  margin-bottom: 20px;
}

.wrap-image {
  position: relative;
  width: 100%;
  overflow: hidden;
}

#cboxLoadedContent {
  background: #ffffff !important;
}

.wrap-image > .chkbox {
  width: 18px;
  height: 18px;
  position: absolute;
  top: 5px;
  right: 5px;
  background: #ffffff;
  text-align: center;
  display: none;
  cursor: pointer;
  border: 1px solid #DDDDDD;
}

.wrap-image > .chkbox:hover {
  border-color: rgba(82, 168, 236, 0.8);
  box-shadow: 0 0 12px rgba(82, 168, 236, 0.6);
  outline: 0 none;
}

.wrap-image.selected > .chkbox {
  display: block;
}

.wrap-image:hover > .chkbox {
  display: block;
}

.wrap-image.selected > .chkbox:before {
  content: "\e0df";
  font-family: "clip-font";
  height: 14px;
  left: 1px;
  position: absolute;
  top: -2px;
  width: 14px;
}

.wrap-image > .tools {
  background-color: rgba(0, 0, 0, 0.55);
  bottom: 0;
  left: -30px;
  position: absolute;
  text-align: center;
  top: 0;
  transition: all 0.2s ease 0s;
  vertical-align: middle;
  width: 24px;
}

.wrap-image > .tools a:hover, .wrap-image > .tools a:focus {
  text-decoration: none;
}

.wrap-image > .tools.tools-bottom {
  bottom: -30px;
  height: 28px;
  left: 0;
  right: 0;
  top: auto;
  width: auto;
}

.wrap-image:hover > .tools {
  left: 0;
}

.wrap-image:hover > .tools.tools-bottom {
  bottom: 0;
  top: auto;
}

.wrap-image:hover > .tools.tools-top {
  bottom: auto;
  top: 0;
}

.wrap-image:hover > .tools.tools-right {
  left: auto;
  right: 0;
}

.wrap-image > .tools > a, .wrap-image > *:first-child .inner a {
  color: #FFFFFF;
  display: inline-block;
  font-size: 14px;
  font-weight: normal;
  padding: 4px;
}

.wrap-image > .tools > a:hover, .wrap-image > *:first-child .inner a:hover {
  color: #C9E2EA;
  text-decoration: none;
}

.wrap-image .tools.tools-bottom > a, .wrap-image .tools.tools-top > a {
  display: inline-block;
}

/* ---------------------------------------------------------------------- */
/*	Compatibility with font-awesome 3
/* ---------------------------------------------------------------------- */
[class^=icon-],
[class*=" icon-"] {
  font-family: FontAwesome;
  font-weight: normal;
  font-style: normal;
  text-decoration: inherit;
  -webkit-font-smoothing: antialiased;
  *margin-right: 0.3em;
}

[class^=icon-]:before,
[class*=" icon-"]:before {
  text-decoration: inherit;
  display: inline-block;
  speak: none;
}

a [class^=icon-],
a [class*=" icon-"] {
  display: inline;
}

.icon-magic:before {
  content: "\f0d0";
}

.icon-bold:before {
  content: "\f032";
}

.icon-italic:before {
  content: "\f033";
}

.icon-underline:before {
  content: "\f0cd";
}

.icon-eraser:before {
  content: "\f12d";
}

.icon-font:before {
  content: "\f031";
}

.icon-list-ul:before {
  content: "\f0ca";
}

.icon-list-ol:before {
  content: "\f0cb";
}

.icon-align-left:before {
  content: "\f036";
}

.icon-text-height:before {
  content: "\f034";
}

.icon-table:before {
  content: "\f0ce";
}

.icon-link:before {
  content: "\f0c1";
}

.icon-picture:before {
  content: "\f03e";
}

.icon-fullscreen:before {
  content: "\f0b2";
}

.icon-question:before {
  content: "\f128";
}

.icon-chevron-up:before {
  content: "\f077";
}

.icon-chevron-down:before {
  content: "\f078";
}

.icon-arrow-left:before {
  content: "\f060";
}

.icon-arrow-right:before {
  content: "\f061";
}

.icon-wrench:before {
  content: "\f0ad";
}

.icon-refresh:before {
  content: "\f021";
}

.icon-remove:before {
  content: "\f00d";
}

.icon-resize-full:before {
  content: "\f065";
}

.icon-check-empty:before {
  content: "\f096";
}

.icon-check:before {
  content: "\f046";
}

.icon-ok:before {
  content: "\f00c";
}

.icon-external-link-sign:before {
  content: "\f14c";
}

.icon-double-angle-left:before {
  content: "\f100";
}

.icon-double-angle-right:before {
  content: "\f101";
}

/* ---------------------------------------------------------------------- */
/*	Animation Page
/* ---------------------------------------------------------------------- */
.animation-container {
  overflow: hidden;
  padding: 140px 0 40px;
  background-color: #F4F4F4;
}

.animation-buttons .btn {
  margin-bottom: 10px;
}

#object {
  display: block;
  margin-bottom: 100px;
  margin-left: auto;
  margin-right: auto;
  font-size: 150px;
  color: #007AFF;
}

/* ---------------------------------------------------------------------- */
/*	X-editable Table
/* ---------------------------------------------------------------------- */
td.column-left {
  width: 25%;
}

td.column-right {
  width: 75%;
}

.trix-without-attachment .trix-button-group--file-tools {
  display: none !important;
}