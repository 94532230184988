.m20 {
  margin: 20px 0 10px 0;
}

.panel-heading {
  padding-left: 15px !important;
}

.content-with-image {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.content-with-image .icon {
  flex-basis: 70px;
  flex-grow: 0;
  flex-shrink: 0;
  text-align: center;
}
.content-with-image .text {
  padding: 0 20px;
}
.content-with-image .button {
  padding: 0 20px;
}
.content-with-image .button .btn {
  width: 105px;
}

.dashboard-widget img {
  margin: 15px 0 25px 0;
}
.dashboard-widget form {
  margin-bottom: 15px;
}

.text-grey {
  font-size: 70%;
  color: gray;
  margin-top: 10px;
}

#demo-content form {
  margin: 20px 0 10px 0;
}

#stats table {
  margin-bottom: 0;
}
#stats table tr:first-of-type td {
  border-top: 0;
}