.m20 {
  margin: 20px 0 10px 0;
}

.panel-heading {
  padding-left: 15px !important;
}

.content-with-image {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .icon {
    flex-basis: 70px;
    flex-grow: 0;
    flex-shrink: 0;
    text-align: center;
  }

  .text {
    padding: 0 20px;
  }

  .button {
    padding: 0 20px;

    .btn {
      width: 105px;
    }
  }
}

.dashboard-widget {
  img {
    margin: 15px 0 25px 0;
  }

  form {
    margin-bottom: 15px;
  }
}

.text-grey {
  font-size: 70%;
  color: gray;
  margin-top: 10px;
}

#demo-content {
  form {
    margin: 20px 0 10px 0;
  }
}

#stats {
  table {
    margin-bottom: 0;

    tr:first-of-type {
      td {
        border-top: 0;
      }
    }
  }
}
