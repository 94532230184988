.dropzone {
  border: 1px dashed rgba(0,0,0,0.3);
  background-color: rgba(0,0,0,0.02);
  margin-bottom: 10px;
}

.dropzone .dz-message {
  padding: 30px 10;
  height: 80px;
  text-align: center;
}

.dz-message span {
  font-size:30px;
  color:gray;
}

.image-container {
  position: relative;
}

.images-gallery {
  display: flex;
  margin-bottom: 20px;
  flex-wrap: wrap;
}

.images-gallery .image-container {
  flex-basis: 30%;
  margin-right: 15px;
  margin-bottom: 10px;
  flex-shrink: 0;
}

.imgs-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.img-container {
  position: relative;
  display: inline-block;
  margin-bottom: 10px;
}

.img-container:last-child {
  margin-bottom: 0 !important;
}

.media-over-icon-container  {
  position:absolute;
  right:10px;
  top:10px;
}

.media-over-icon-container a {
  color: white !important;
}
