//= require font-awesome
//= require panel/common/bootstrap.min
//= require panel/common/style
//= require panel/common/main
//= require panel/common/main-responsive
//= require_tree ./panel/common/skins
//= require panel/common/bootstrap-colorpalette
//= require panel/common/perfect-scrollbar
//= require panel/common/theme_light
//= require panel/other/bootstrap-switch
//= require jquery.minicolors
//= require panel/other/datepicker
//= require jBox
//= require panel/dashboard/custom
//= require panel/other/jquery.datetimepicker
//= require donwload_floorplan

ul.ui-autocomplete {
  position: absolute;
  list-style: none;
  margin: 0;
  padding: 0;
  border: solid 1px #999;
  cursor: default;
  li {
    background-color: #FFF;
    border-top: solid 1px #DDD;
    margin: 0;
    padding: 0;
    a {
      color: #000;
      display: block;
      padding: 3px;
    }
    a.ui-state-hover, a.ui-state-active {
      background-color: #FFFCB2;
    }
  }
}


.navigation-small ul.main-navigation-menu div {display:none;}


.dd-handle {

  background:white;
  border-color:#428bca;
  color:#428bca;
  overflow:hidden;

}


.dd-item.draggable{cursor:move;}

.clip-camera-2{
  font-size:50px;
  color:#5dbbe3;
}

.img-thumbnail.logo{
  background-color:#f0ad4e;
}

.dd-handle.multiline {
  height:unset;
}

.assistant-link-image {
  width: 16px;
  height: 16px;
  margin-right: 5px;
  margin-top: -4px;
}
.nodata-container {
  margin: 80px 0;
  text-align: center;

  .nodata-state {
    color: #7f8c8d;
    font-size: 120%;
    font-weight: bold;
  }
}

.flex-form {
  display: flex;
  align-items: center;

  label {
    margin-bottom: 0 !important;
  }

  label, input {
    margin-right: 10px;
  }

  input:last-of-type {
    margin-right: 0 !important;
  }

  .btn-small {
    font-size: 11px;
  }
}

#robot-alert {
  width: 50%;
}

li.balance {
  height: 54px;

  div {
    padding: 15px 10px;
    position: relative;
    width: 100%;
    height: 100%;

    a {
      display: block;
      padding-top: 2px;
      height: 100%;
      font-size: 1.2em;
      white-space: nowrap;
      color: #666;
    }
  }
}

span.search-success {
  color: #5cb85c
}

span.search-failed {
  color: #d9534f
}

.panel-heading-with-fa {
  margin-left: 35px;
}

.media--icon-container {
  top: 0;
  right: 0;
  padding: 2px 4px;
  border-bottom-left-radius: 5px;
  position: absolute;
  background-color: white;

  a {
    display: inline-block;
    padding: 3px 5px;
    font-size: 1.2em;
  }
}
