ul.ui-autocomplete {
  position: absolute;
  list-style: none;
  margin: 0;
  padding: 0;
  border: solid 1px #999;
  cursor: default;
}
ul.ui-autocomplete li {
  background-color: #FFF;
  border-top: solid 1px #DDD;
  margin: 0;
  padding: 0;
}
ul.ui-autocomplete li a {
  color: #000;
  display: block;
  padding: 3px;
}
ul.ui-autocomplete li a.ui-state-hover, ul.ui-autocomplete li a.ui-state-active {
  background-color: #FFFCB2;
}

.navigation-small ul.main-navigation-menu div {
  display: none;
}

.dd-handle {
  background: white;
  border-color: #428bca;
  color: #428bca;
  overflow: hidden;
}

.dd-item.draggable {
  cursor: move;
}

.clip-camera-2 {
  font-size: 50px;
  color: #5dbbe3;
}

.img-thumbnail.logo {
  background-color: #f0ad4e;
}

.dd-handle.multiline {
  height: unset;
}

.assistant-link-image {
  width: 16px;
  height: 16px;
  margin-right: 5px;
  margin-top: -4px;
}

.nodata-container {
  margin: 80px 0;
  text-align: center;
}
.nodata-container .nodata-state {
  color: #7f8c8d;
  font-size: 120%;
  font-weight: bold;
}

.flex-form {
  display: flex;
  align-items: center;
}
.flex-form label {
  margin-bottom: 0 !important;
}
.flex-form label, .flex-form input {
  margin-right: 10px;
}
.flex-form input:last-of-type {
  margin-right: 0 !important;
}
.flex-form .btn-small {
  font-size: 11px;
}

#robot-alert {
  width: 50%;
}

li.balance {
  height: 54px;
}
li.balance div {
  padding: 15px 10px;
  position: relative;
  width: 100%;
  height: 100%;
}
li.balance div a {
  display: block;
  padding-top: 2px;
  height: 100%;
  font-size: 1.2em;
  white-space: nowrap;
  color: #666;
}

span.search-success {
  color: #5cb85c;
}

span.search-failed {
  color: #d9534f;
}

.panel-heading-with-fa {
  margin-left: 35px;
}

.media--icon-container {
  top: 0;
  right: 0;
  padding: 2px 4px;
  border-bottom-left-radius: 5px;
  position: absolute;
  background-color: white;
}
.media--icon-container a {
  display: inline-block;
  padding: 3px 5px;
  font-size: 1.2em;
}